import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Title } from '../../components/Title';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { TextInput, InputWithButton } from '../../components/TextInput';
import ProductsAPI from '../../api/ProductsAPI';
import UsersAPI from '../../api/UsersAPI';

class FiltersPage extends Component {

    constructor() {
        super();
        this.state = {
            keyword: '',
            filters: [],
        }

    }

    componentDidMount() {
        this.loadFilters();
        this.checkUserIsAdmin();
    }

    checkUserIsAdmin() {
        UsersAPI.getUserData((data) => {
            UsersAPI.getUser(data.id, (_usr) => {
                if (_usr.userLevel !== 'admin') {
                    this.props.history.push(`/`);
                }
            });
        });
    }

    loadFilters() {
        ProductsAPI.getFilters((data) => {
            console.log(data);
            this.setState({
                filters: data
            });
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    render() {

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <Title title="Filters" button="Add new" link="/filters/new" />

                        <Container>

                            <div className="row">
                                <div className="col-sm-12">
                                    <TextInput
                                        name="keyword"
                                        placeholder="Search filters"
                                        text="Search"
                                        value={this.state.keyword}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Filter</th>
                                                <th>Options</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.filters.filter(d => this.state.keyword === '' || d.label.includes(this.state.keyword.toLowerCase())).map((item, index) =>
                                                <tr key={index}>
                                                    <td><Link to={`/filters/${item.id}`}>{item.label}</Link></td>
                                                    <td>
                                                        {item.options.map((itemx, indexx) =>
                                                            <span key={indexx} className="grey-warning" style={{ fontSize: 13, float: 'left', lineHeight: '25px', cursor: 'pointer', marginRight: 8, marginTop: 4, marginBottom: 4, whiteSpace: 'pre' }}>{itemx.value}</span>
                                                        )}
                                                    </td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </Container>

                    </div>
                </div>
            </div>
        )

    }

}

FiltersPage.propTypes = {
    firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        firstName: state.account.firstName,
    }
};

export default connect(mapStateToProps)(FiltersPage);