import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { Title } from '../../components/Title';
import { Divider } from '../../components/Divider';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { TextInput, TextArea, CurrencyInput, InputWithButton } from '../../components/TextInput';
import { SelectBox } from '../../components/SelectBox';
import ProductsAPI from '../../api/ProductsAPI';
import LookupAPI from '../../api/LookupAPI';
import StoresAPI from '../../api/StoresAPI';
import Loader from '../../components/Loader/Loader';
import { WarningBar } from '../../components/WarningBar';
import UsersAPI from '../../api/UsersAPI';
import { Notes } from '../../components/Notes';

class ProductPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            storeId: '',
            isLoading: false,
            title: '',
            barcode: '',
            price: 0,
            description: '',
            intro: '',
            status: '',
            images: [],
            features: [],
            hasErrors: false,
            errTitle: '',
            errBarcode: '',
            errPrice: '',
            errIntro: '',
            catLevel1: '',
            catLevel2: '',
            catLevel3: '',
            googleId: '',
            errGoogleId: '',
            errStoreId: '',
            level1: [],
            level2: [],
            level3: [],
            serpResults: [],
            stores: [],
            filters: [],
            collectionFilters: [],
            noResults: false,
            lookup: '',
            errLookup: '',
            errImages: '',
            conditions: [{
                value: '',
                label: 'Select...',
            }, {
                value: 'new',
                label: 'Brand New',
            }, {
                value: 'other',
                label: 'New Other',
            }, {
                value: 'refurbished',
                label: 'Refurbished',
            }, {
                value: 'used',
                label: 'Pre-Owned',
            }],
            grades: [{
                value: '',
                label: 'Select...',
            }, {
                value: 'A',
                label: 'A',
            }, {
                value: 'B',
                label: 'B',
            }, {
                value: 'C',
                label: 'C',
            }, {
                value: 'D',
                label: 'D',
            }, {
                value: 'F',
                label: 'F',
            }],
            refurbGrades: [{
                value: '',
                label: 'Select...',
            }, {
                value: 'A',
                label: 'A',
            }, {
                value: 'B',
                label: 'B',
            }, {
                value: 'C',
                label: 'C',
            }],
            fulfilmentOptions: [{
                value: '',
                label: 'Select...',
            }, {
                value: 'delivery',
                label: 'Delivery Only',
            }, {
                value: 'collection',
                label: 'Collection Only',
            }, {
                value: 'anyfulfilment',
                label: 'Delivery or Collection',
            }],
            condition: '',
            conditionText: '',
            grade: '',
            onSale: true,
            selectedFile: '',
            selectedFileName:'',
            quantity: 1,
            wasPrice: 0,
            errWasPrice: '',
            errQuantity: '',
            gtin: '',
            googleUrl: '',
            shopifyId: '',
            fulfilmentOption: '',
            errFulfilmentOption: '',
            saveEnabled: true,
            version: '',
            userId: '',
            isSold: false,
            costPrice: 0,
            errCostPrice: '',
            searchValues:this.props.location.state,
        };
        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.fetchProduct = this.fetchProduct.bind(this);
        this.addFeature = this.addFeature.bind(this);
        this.selectProduct = this.selectProduct.bind(this);
        this.deleteFeature = this.deleteFeature.bind(this);
        this.selectFilter = this.selectFilter.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.attach = this.attach.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
    }

    componentDidMount() {
        import('../../../package.json').then(({ version }) => {
            this.setState({ version });
        });
        if (this.state.id !== 'new') {
            this.loadProduct();
        }
        this.loadCollection('00000000-0000-0000-0000-000000000000', 1);
        this.loadStores();
    }

    loadStores() {
        UsersAPI.getUserData((data) => {
            this.setState({
                userId: data.id
            });
            UsersAPI.getUserStores(data.id, (data) => {
                var _arr = [];
                _arr.push({
                    value: '',
                    label: 'Select...',
                });
                for (var i = 0; i < data.length; i++) {
                    if(data[i] !== null){
                        _arr.push({
                            value: data[i].id,
                            label: data[i].title,
                        });
                    }
                    
                }
                this.setState({
                    stores: _arr
                });
            });
        });
    }

    loadCollectionFilters() {
        var collectionId = '00000000-0000-0000-0000-000000000000';
        if (this.state.catLevel3 !== '') {
            collectionId = this.state.catLevel3;
        } else if (this.state.catLevel2 !== '') {
            collectionId = this.state.catLevel2;
        } else if (this.state.catLevel1 !== '') {
            collectionId = this.state.catLevel1;
        } else {
            collectionId = '00000000-0000-0000-0000-000000000000';
        }

        ProductsAPI.getCollectionFilters(collectionId, (data) => {
            this.setState({
                collectionFilters: data
            });
        })

    }

    loadCollection(id, level) {
        ProductsAPI.getCollections(id, (data) => {
            console.log("loadCollection('" + id + "', " + level + ")", data);
            var _arr = [];
            _arr.push({
                value: '',
                label: 'Select...',
            });
            for (var i = 0; i < data.length; i++) {
                _arr.push({
                    value: data[i].id,
                    label: data[i].title,
                });
            }
            if (level === 1) {
                this.setState({
                    level1: _arr,
                });
            }
            else if (level === 2) {
                this.setState({
                    level2: _arr,
                });
            }
            else if (level === 3) {
                this.setState({
                    level3: _arr,
                });
            }
        })
    }

    loadProduct() {
        ProductsAPI.getProduct(this.state.id, (data) => {
            this.setState({
                title: data.title,
                barcode: data.barcode,
                price: data.price,
                status: data.status,
                images: data.images,
                description: data.description,
                intro: data.intro,
                features: data.features,
                googleId: data.googleId,
                catLevel1: data.catLevel1,
                catLevel2: data.catLevel2,
                catLevel3: data.catLevel3,
                storeId: data.storeId,
                filters: data.filters,
                condition: data.condition,
                conditionText: data.conditionText,
                grade: data.grade,
                onSale: data.onSale,
                wasPrice: data.wasPrice,
                gtin: data.gtin,
                shopifyId: data.shopifyId,
                quantity: data.quantity,
                fulfilmentOption: data.fulfilmentOption,
                isSold: data.isSold,
                costPrice: data.costPrice,
            }, () => {
                if (data.catLevel1 !== '') {
                    this.loadCollection(data.catLevel1, 2);
                }
                if (data.catLevel2 !== '') {
                    this.loadCollection(data.catLevel2, 3);
                }
                this.loadCollectionFilters();
            })
        })
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
            hasErrors: false,
            errTitle: '',
            errBarcode: '',
            errPrice: '',
            errGoogleId: '',
            errStoreId: '',
            errWasPrice: '',
            errQuantity: '',
            errFulfilmentOption: '',
            errCostPrice: '',
            noResults: false,
        }, () => {
            if (name === 'catLevel1') {
                this.setState({
                    catLevel2: '',
                    catLevel3: '',
                    level2: [],
                    level3: []
                }, () => {
                    this.loadCollectionFilters();
                });
                this.loadCollection(this.state.catLevel1, 2);
            } else if (name === 'catLevel2') {
                this.setState({
                    catLevel3: '',
                    level3: []
                }, () => {
                    this.loadCollectionFilters();
                });
                this.loadCollection(this.state.catLevel2, 3);
            } else if (name === 'catLevel3') {
                this.loadCollectionFilters();
            }
        });
    }

    save() {

        var _ok = true;

        if (this.state.title === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errTitle: 'Please enter the product name'
            });
        }
        if (this.state.barcode === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errBarcode: 'Please enter the product barcode'
            });
        }
        if (this.state.storeId === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errStoreId: 'Please select the store'
            });
        }
        if (this.state.price === '' || parseInt(this.state.price) <= 0) {
            _ok = false;
            this.setState({
                hasErrors: true,
                errPrice: 'Please enter the product price'
            });
        } else {
            if (this.state.wasPrice !== '' && parseInt(this.state.wasPrice) > 0 && parseInt(this.state.wasPrice) < parseInt(this.state.price)) {
                _ok = false;
                this.setState({
                    hasErrors: true,
                    errWasPrice: 'Must be higher than current price'
                });
            }
        }

        if (this.state.quantity === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errQuantity: 'Please enter the product quantity'
            });
        }
        if (this.state.condition === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errCondition: 'Please select the product condition'
            });
        }
        if (this.state.fulfilmentOption === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errFulfilmentOption: 'Please select a fulfilment option'
            });
        }
        if ((this.state.condition === 'refurbished' || this.state.condition === 'used') && this.state.grade === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errGrade: 'Please select the product grade'
            });
        }

        if (_ok) {

            let checkPayload = {
                id: this.state.id === 'new' ? '' : this.state.id,
                version: this.state.version,
                userId: this.state.userId,
                barcode: this.state.barcode,
                title: this.state.title,
                price: this.state.price,
            }

            ProductsAPI.checkProduct(checkPayload, (data) => {

                if (!data.valid) {
                    _ok = false;
                    this.setState({
                        hasErrors: true,
                        errBarcode: data.message
                    });
                }
                else {

                    let payload = {
                        barcode: this.state.barcode,
                        storeId: this.state.storeId,
                        title: this.state.title,
                        price: this.state.price,
                        intro: this.state.intro,
                        googleId: this.state.googleId,
                        catLevel1: this.state.catLevel1,
                        catLevel2: this.state.catLevel2,
                        catLevel3: this.state.catLevel3,
                        images: this.state.images,
                        features: this.state.features,
                        filters: this.state.filters,
                        condition: this.state.condition,
                        conditionText: this.state.conditionText,
                        grade: this.state.grade,
                        onSale: this.state.onSale,
                        wasPrice: this.state.wasPrice,
                        gtin: this.state.gtin,
                        quantity: this.state.quantity,
                        fulfilmentOption: this.state.fulfilmentOption,
                        costPrice: this.state.costPrice,
                    }

                   
                    this.setState({
                        saveEnabled: false,
                    }, () => {
                        if (this.state.id === 'new') {
                            ProductsAPI.addProduct(payload, (data) => {
                                this.props.history.push(`/products`);
                            });
                        } else {
                            ProductsAPI.updProduct(this.state.id, payload, (data) => {
                                this.props.history.push(`/products`);
                            });
                        }
                    });

                }

            });

        }

    }

    fetchProduct() {
        if (this.state.lookup !== '') {
            this.setState({
                isLoading: true,
                serpResults: [],
                noResults: false,
            }, () => {
                LookupAPI.getResults(this.state.lookup, (data) => {
                    if (data) {
                        this.setState({
                            serpResults: data,
                            isLoading: false,
                        });
                        if (data.length === 0) {
                            this.setState({
                                noResults: true
                            })
                        }
                    } else {
                        this.setState({
                            serpResults: [],
                            isLoading: false,
                            noResults: true,
                        });
                    }
                })
            });
        }
    }

    addFeature() {
        this.setState({
            features: this.state.features.concat([{ value: this.state.feature }]),
            feature: '',
        });
    }


    deleteFeature(_index) {
        this.setState({ features: this.state.features.filter((item, index) => index !== _index) });
    }

    deleteImage(_index) {
        this.setState({ images: this.state.images.filter((item, index) => index !== _index) });
    }

    selectFilter(_value) {
        this.setState({ filters: this.state.filters.concat([{ value: _value }]) });
    }

    removeFilter(_value) {
        this.setState({ filters: this.state.filters.filter((item, index) => item.value !== _value) });
    }

    selectProduct(id) {
        this.setState({
            isLoading: true,
            serpResults: [],
        }, () => {
            LookupAPI.getProduct(id, (data) => {
                
                var _images = [];
                if (data.product_results.media) {
                    for (var i = 0; i < data.product_results.media.length; i++) {
                        _images.push({ src: data.product_results.media[i].link });
                    }
                }

                var _features = [];
                if (data.product_results.extensions) {
                    for (var i = 0; i < data.product_results.extensions.length; i++) {
                        _features.push({ value: data.product_results.extensions[i] });
                    }
                }

                var _gtin = '';
                if (data.specs_results) {
                    if (data.specs_results.universal_product_identifiers) {
                        if (data.specs_results.universal_product_identifiers.gtin) {
                            _gtin = data.specs_results.universal_product_identifiers.gtin;
                        }
                    }
                }

                var _googleUrl = '';
                if (data.search_metadata) {
                    if (data.search_metadata.google_product_url) {
                        _googleUrl = data.search_metadata.google_product_url;
                    }
                }

                this.setState({
                    isLoading: false,
                    title: data.product_results.title,
                    googleId: id,//data.product_results.product_id,
                    intro: data.product_results.description,
                    images: _images,
                    features: _features,
                    gtin: _gtin,
                    googleUrl: _googleUrl,
                });
            })
        });
    }

    delete() {
        ProductsAPI.deleteProduct(this.state.id, (data) => {
            this.props.history.push('/products');
        })
    }

    cancel() {
        if (this.state.id === 'new') {
            this.props.history.push('/products');
        } else {
            this.props.history.push(`/products/${this.state.id}`);
        }
    }

    attach() {

        if (this.state.selectedFile !== '' && this.state.selectedFile !== null) {

            var formData = new FormData()
            formData.append('file', this.state.selectedFile);

            ProductsAPI.addAttachment(this.state.id, formData, (res) => {
                if (res.data) {
                    this.setState({
                        images: this.state.images.concat([{ src: res.data.url }]),
                        selectedFile: '',
                    });
                }
            });

        }

    }

    onChangeHandler = event => {
        console.log(event.target.files[0])
        if(event.target.files.length > 0){
            this.setState({selectedFile: event.target.files[0],
               // selectedFileName: event.target.files[0].name,
            });
        }else{
           // this.setState({selectedFileName:'Choose a File...'})
        }
        
    }

    render() {

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <NarrowContainer>
                            {this.state.id === 'new' &&
                                <div>
                                    <Link className="back" to= {`/products/`}><i className="fas fa-chevron-left"></i>Back</Link>
                                </div>
                            }
                            {this.state.id !== 'new' &&
                                <div>
                                <Link className="back" to={{pathname: `/products/`,state: {storeId: `${this.state.searchValues.storeId}`, keyword: `${this.state.searchValues.keyword}`, fromDate: `${this.state.searchValues.fromDate}`,toDate: `${this.state.searchValues.toDate}`,status:`${this.state.searchValues.status}`,page:`${this.state.searchValues.page}`,catLevel1:`${this.state.searchValues.catLevel1}`,catLevel2:`${this.state.searchValues.catLevel2}`,catLevel3:`${this.state.searchValues.catLevel3}`}}}><i className="fas fa-chevron-left"></i>Back</Link>
                                </div>
                            }
                            <Title title={this.state.id === 'new' ? "New Product" : "Edit Product"} />

                            <FormContainer title="Product Lookup"
                                description={`Find a product and pre-populate your product listing.`}>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <InputWithButton
                                            name="lookup"
                                            type="text"
                                            label="Product Lookup"
                                            value={this.state.lookup}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errLookup}
                                            onClick={this.fetchProduct}
                                            text="Lookup"
                                            placeholder="Find product..."
                                        />
                                    </div>
                                </div>

                                {this.state.isLoading &&
                                    <Loader />
                                }

                                {this.state.noResults &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p className="invalid-error">No products were found matching your query.</p>
                                        </div>
                                    </div>
                                }

                                {this.state.serpResults.length > 0 &&
                                    <div className="row">
                                        <div className="col-sm-12">

                                            {this.state.serpResults.map((item, index) =>
                                                <div>
                                                    {(item.product_id && item.product_id !== '') &&
                                                        <div key={index} className="row results-item">
                                                            <div className="col-sm-2 text-center">
                                                                <a href={`https://www.google.co.uk/shopping/product/${item.product_id}`} target="_blank"><img style={{ width: '80px' }} src={item.thumbnail} /></a>
                                                            </div>
                                                            <div className="col-sm-8">
                                                                <p><a href={`https://www.google.co.uk/shopping/product/${item.product_id}`} target="_blank">{item.title}</a></p>
                                                                {item.extensions &&
                                                                    <p>{item.extensions.map((itemx, indexx) =>
                                                                        <span key={indexx} className="grey-warning" style={{ float: 'left', marginRight: 5, whiteSpace: 'pre', marginBottom: 5, fontSize: 11 }}>{itemx}</span>
                                                                    )}</p>
                                                                }
                                                            </div>
                                                            <div className="col-sm-2 text-right">
                                                                <SubmitButton
                                                                    text="Select"
                                                                    className="btn btn-secondary"
                                                                    onClick={() => this.selectProduct(item.product_id)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                }

                            </FormContainer>

                            <FormContainer title="Product Information"
                                description={`Please add the details for this product.`}>

                                <div className="row">
                                    <div className="col-sm-10">
                                        <TextInput
                                            name="title"
                                            type="text"
                                            label="Product Name"
                                            value={this.state.title}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errTitle}
                                        />
                                    </div>
                                    <div className="col-sm-2">
                                        <SwitchButton
                                            label="On Sale"
                                            name="onSale"
                                            value={this.state.onSale}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <SelectBox
                                            name="storeId"
                                            label="Store"
                                            value={this.state.storeId}
                                            options={this.state.stores}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errStoreId}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="googleId"
                                            type="text"
                                            label="Google Product ID"
                                            value={this.state.googleId}
                                            maxLength={45}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errGoogleId}
                                        />
                                        {(this.state.googleUrl != '') &&
                                            <div style={{ fontSize: '10px', marginTop: '-15px', marginBottom: '20px' }}>
                                                <a target="_blank" href={this.state.googleUrl}>View on Google</a>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <SelectBox
                                            name="fulfilmentOption"
                                            label="Fulfilment Method"
                                            value={this.state.fulfilmentOption}
                                            options={this.state.fulfilmentOptions}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errFulfilmentOption}
                                        />
                                    </div>

                                    <div className="col-sm-6">
                                        <TextInput
                                            name="quantity"
                                            type="number"
                                            label="Quantity"
                                            value={this.state.quantity}
                                            maxLength={45}
                                            asterix={true}
                                            step={1}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errQuantity}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <TextInput
                                            name="price"
                                            type="number"
                                            label="Current Price"
                                            value={this.state.price}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errPrice}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextInput
                                            name="wasPrice"
                                            type="number"
                                            label="Was Price"
                                            value={this.state.wasPrice}
                                            maxLength={45}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errWasPrice}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <TextInput
                                            name="costPrice"
                                            type="number"
                                            label="Cost Price"
                                            value={this.state.costPrice}
                                            maxLength={45}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errCostPrice}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="gtin"
                                            type="text"
                                            label="GTIN"
                                            value={this.state.gtin}
                                            maxLength={150}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="barcode"
                                            type="text"
                                            label="Barcode"
                                            value={this.state.barcode}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errBarcode}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <SelectBox
                                            name="condition"
                                            label="Condition"
                                            value={this.state.condition}
                                            options={this.state.conditions}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errCondition}
                                        />
                                    </div>
                                    {(this.state.condition === 'refurbished' || this.state.condition === 'used') &&
                                        <div className="col-sm-6">
                                            <SelectBox
                                                name="grade"
                                                label="Grade"
                                                value={this.state.grade}
                                                options={this.state.condition === 'refurbished' ? this.state.refurbGrades : this.state.grades}
                                                asterix={true}
                                                onChange={this.onChange.bind(this)}
                                                error={this.state.errGrade}
                                            />
                                        </div>
                                    }
                                </div>

                                {(this.state.condition !== '' && this.state.condition !== 'new') &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <TextInput
                                                name="conditionText"
                                                type="text"
                                                label="Condition Description"
                                                value={this.state.conditionText}
                                                maxLength={500}
                                                onChange={this.onChange.bind(this)}
                                            />
                                        </div>
                                    </div>
                                }

                                <div className="row">
                                    <div className="col-sm-4">
                                        <SelectBox
                                            name="catLevel1"
                                            label="Product Category"
                                            value={this.state.catLevel1}
                                            options={this.state.level1}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    {this.state.level2.length > 1 &&
                                        <div className="col-sm-4">
                                            <SelectBox
                                                name="catLevel2"
                                                label=" "
                                                value={this.state.catLevel2}
                                                options={this.state.level2}
                                                onChange={this.onChange.bind(this)}
                                            />
                                        </div>
                                    }
                                    {this.state.level3.length > 1 &&
                                        <div className="col-sm-4">
                                            <SelectBox
                                                name="catLevel3"
                                                label=" "
                                                value={this.state.catLevel3}
                                                options={this.state.level3}
                                                onChange={this.onChange.bind(this)}
                                            />
                                        </div>
                                    }
                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <TextArea
                                            name="intro"
                                            type="text"
                                            label="Product Description"
                                            value={this.state.intro}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errIntro}
                                            rows={8}
                                        />
                                    </div>
                                </div>

                                {(this.state.shopifyId !== '') &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <small>Shopify ID: {this.state.shopifyId}</small>
                                        </div>
                                    </div>
                                }

                            </FormContainer>

                            <Divider />

                            <FormContainer title="Filter Attributes"
                                description={`Filter for this product based on selected collection.`}>
                                {this.state.collectionFilters.length === 0 &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p>No filter attributes available.</p>
                                        </div>
                                    </div>
                                }
                                {this.state.collectionFilters.length > 0 &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <table>
                                                <tbody>
                                                    {this.state.collectionFilters.map((item, index) =>
                                                        <tr key={index}>
                                                            <td width="20%">{item.label}</td>
                                                            <td width="80%">
                                                                {item.options.map((itemx, indexx) =>
                                                                    <div>
                                                                        {this.state.filters.filter(filter => (filter.value === itemx.value)).length > 0 ?
                                                                            <span className="yellow-warning" style={{ fontSize: 13, float: 'left', lineHeight: '25px', cursor: 'pointer', marginRight: 8, whiteSpace: 'pre', marginBottom: 4, marginTop: 4 }} onClick={(e) => { this.removeFilter(itemx.value) }}>{itemx.value}</span>
                                                                            :
                                                                            <span className="grey-warning" style={{ fontSize: 13, float: 'left', lineHeight: '25px', cursor: 'pointer', marginRight: 8, whiteSpace: 'pre', marginBottom: 4, marginTop: 4 }} onClick={(e) => { this.selectFilter(itemx.value) }}>{itemx.value}</span>
                                                                        }
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                            </FormContainer>

                            <Divider />

                            <FormContainer title="Product Features"
                                description={`Bullet point features of this product.`}>
                                {this.state.features.length === 0 &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p>No features available.</p>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-sm-12">
                                        {this.state.features.map((item, index) =>
                                            <span className="yellow-warning" style={{ fontSize: 13, float: 'left', marginRight: 8, whiteSpace: 'pre', marginBottom: 8 }} onClick={(e) => { this.deleteFeature(index) }}>{item.value} <i style={{ marginTop: 5 }} className="far fa-times-circle"></i></span>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <InputWithButton
                                            name="feature"
                                            type="text"
                                            label="Add Feature"
                                            value={this.state.feature}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errFeature}
                                            onClick={this.addFeature}
                                            text="Add"
                                        />
                                    </div>
                                </div>
                            </FormContainer>

                            <Divider />


                            <FormContainer title="Product Media"
                                description={`Images for this product.`}>
                                {this.state.images.length === 0 &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p>No images available.</p>
                                        </div>
                                    </div>
                                }
                                 {this.state.images.length > 4 &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                        <span className="yellow-warning">Only 4 images can be uploaded.</span>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    {this.state.images.map((item, index) =>
                                        <div key={index} className="col-sm-4">
                                            <div className="image-preview">
                                                <span className="image-preview-del" onClick={(e) => { this.deleteImage(index) }}><i style={{ marginTop: 5 }} className="far fa-times-circle"></i></span>
                                                <img src={item.src} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <hr />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <input type="file" name="file" className="form-control"  onChange={this.onChangeHandler} />
                                        <label htmlFor="file">{this.state.selectedFileName}</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <SubmitButton
                                            text={'Upload Image'}
                                            className="btn btn-secondary"
                                            onClick={this.attach}
                                        />
                                    </div>
                                </div>
                            </FormContainer>

                            <Divider />

                            {this.state.id !== 'new' &&
                                <FormContainer title="Notes"
                                    description={``}>

                                    <Notes id={this.state.id} />

                                </FormContainer>
                            }

                            {this.state.id !== 'new' &&
                                <Divider />
                            }

                            <WarningBar text="Please note it may take up to 5 minutes for changes to be displayed on the Cash Generator website." />

                            <div className="row">
                                <div className="col-sm-4">
                                    {(this.state.id !== 'new' && !this.state.isSold) &&
                                        <SubmitButton
                                            text="Delete Product"
                                            className="btn btn-secondary"
                                            onClick={(e) => { if (window.confirm('Are you sure you wish to delete this product?')) this.delete() }}
                                        />
                                    }
                                </div>
                                <div className="col-sm-4" style={{ paddingBottom: 60 }}>
                                    <span className="invalid-error">{this.state.hasErrors ? 'Please check the errors above' : ''}</span>
                                </div>
                                <div className="col-sm-4 text-right">
                                    <SubmitButton
                                        text="Cancel"
                                        className="btn btn-secondary"
                                        onClick={this.cancel}
                                    />
                                    <SubmitButton
                                        text="Save Product"
                                        className="btn"
                                        onClick={this.save}
                                        disabled={this.state.saveEnabled === true ? false : true}
                                    />
                                </div>
                            </div>

                        </NarrowContainer>
                    </div>
                </div>
            </div>
        )

    }

}

export default ProductPage;