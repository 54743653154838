import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Title } from '../../components/Title';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { TextInput, InputWithButton } from '../../components/TextInput';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import ProductsAPI from '../../api/ProductsAPI';
import { SelectBox } from '../../components/SelectBox';
import { Loader } from '../../components/Loader';
import UsersAPI from '../../api/UsersAPI';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

class ProductsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            page: 1,
            size: 30,
            count: 0,
            stores: [],
            store: '',
            keyword: '',
            products: [],
            fromDate: '',
            toDate:'',
            catLevel1: '',
            catLevel2: '',
            catLevel3: '',
            level1: [],
            level2: [],
            level3: [],
            status:'onsale',
            statuses: [{
                value: 'onsale',
                label: 'On Sale'
            }, {
                value: 'soldout',
                label: 'Sold Out'
            }, {
                value: 'unavailable',
                label: 'Unavailable'
            }, {
                value: 'uploading',
                label: 'Uploading'
            }],
            searchValues:this.props.location.state,
            
        }

        this.nextPage = this.nextPage.bind(this);
        this.loadProducts = this.loadProducts.bind(this);
        this.reset = this.reset.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.filterResults = this.filterResults.bind(this);

    }

    componentDidMount() {
        if(this.state.searchValues !== undefined && this.state.searchValues !== null){
            if(this.state.searchValues.toDate !== undefined ) {
                this.state.storeId = this.state.searchValues.storeId;
                this.state.keyword = this.state.searchValues.keyword;
                this.state.status = this.state.searchValues.status;
                this.state.page = this.state.searchValues.page;
                this.state.catLevel1 = this.state.searchValues.catLevel1;
                this.state.catLevel2 = this.state.searchValues.catLevel2;
                this.state.catLevel3 = this.state.searchValues.catLevel3;
                this.state.fromDate = this.state.searchValues.fromDate;
                this.state.toDate = this.state.searchValues.toDate;
                
            }
        }
        this.loadStores();
        this.loadCollection('00000000-0000-0000-0000-000000000000', 1);
    }

    reset() {
        this.setState({
            keyword: '',
            fromDate :'',
            toDate:'',
            status: 'onsale',
            catLevel1: '',
            catLevel2: '',
            catLevel3: '',
        }, () => {
            this.loadProducts();
        })
    }

    loadProducts() {

        this.setState({
            isLoading: true
        });

        window.scrollTo(0, 0);

        let _options = {
            store: this.state.storeId,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            keyword: this.state.keyword,
            status: this.state.status,
            level1: this.state.catLevel1,
            level2: this.state.catLevel2,
            level3: this.state.catLevel3,
            page: this.state.page,
        };
        ProductsAPI.getProducts(_options, (data) => {
            this.setState({
                count: data.count,
                isLoading: false,
                products: data.results
            });
        });
    }

    filterResults() {
        this.setState({
            page: 1,
        }, () => {
            this.loadProducts();
        })
    }

    loadStores() {
        UsersAPI.getUserData((data) => {
            UsersAPI.getUserStores(data.id, (data) => {
                var _arr = [];
                for (var i = 0; i < data.length; i++) {
                    if (i === 0) {
                       // if(this.state.storeId ==='null' || this.state.storeId ==='') {
                            this.setState({
                                storeId: data[i].id
                            }, () => {
                                this.loadProducts();
                            })
                        //}else {
                        //    this.loadProducts();
                       // }
                    }
                    if(data[i]!== null){
                        _arr.push({
                            value: data[i].id,
                            label: data[i].title,
                        });
                    }
                }
                this.setState({
                    stores: _arr
                });
            });
        });
    }

    loadCollectionFilters() {
        var collectionId = '00000000-0000-0000-0000-000000000000';
        if (this.state.catLevel3 !== '') {
            collectionId = this.state.catLevel3;
        } else if (this.state.catLevel2 !== '') {
            collectionId = this.state.catLevel2;
        } else if (this.state.catLevel1 !== '') {
            collectionId = this.state.catLevel1;
        } else {
            collectionId = '00000000-0000-0000-0000-000000000000';
        }

        ProductsAPI.getCollectionFilters(collectionId, (data) => {
            this.setState({
                collectionFilters: data
            });
        })

    }

    loadCollection(id, level) {
        ProductsAPI.getCollections(id, (data) => {
            if (data.length > 0) {
                var _arr = [];
                _arr.push({
                    value: '',
                    label: 'Select...',
                });
                for (var i = 0; i < data.length; i++) {
                    _arr.push({
                        value: data[i].id,
                        label: data[i].title,
                    });
                }
                if (level === 1) {
                    this.setState({
                        level1: _arr,
                    });
                }
                else if (level === 2) {
                    this.setState({
                        level2: _arr,
                    });
                }
                else if (level === 3) {
                    this.setState({
                        level3: _arr,
                    });
                }
            }
        })
    }

    prevPage() {
        var page = this.state.page - 1;
        this.setState({
            page: page
        }, () => {
            this.loadProducts();
        });
    }

    nextPage() {
        var page = this.state.page + 1;
        this.setState({
            page: page
        }, () => {
            this.loadProducts();
        });
    }

    /*onChange(name, value) {
        this.setState({
            [name]: value,
        }, () => {
            if (name === 'storeId' || name === 'status') {
                this.loadProducts();
            } else if (name === 'catLevel1') {
                this.setState({
                    catLevel2: '',
                    catLevel3: '',
                    level2: [],
                    level3: []
                }, () => {
                    this.loadCollectionFilters();
                });
                this.loadCollection(this.state.catLevel1, 2);
            } else if (name === 'catLevel2') {
                this.setState({
                    catLevel3: '',
                    level3: []
                }, () => {
                    this.loadCollectionFilters();
                });
                this.loadCollection(this.state.catLevel2, 3);
            } else if (name === 'catLevel3') {
                this.loadCollectionFilters();
            }
        });
    }*/
    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }
    setFromDate(e) {
        if(e!=="" && e!==null){
            this.setState({
                fromDate: e.format("DD/MM/YYYY"),
            });
        }else{
            this.setState({fromDate:''});
        }
    }
    setToDate(e) {
        if(e!=="" && e!==null){
            this.setState({
                toDate: e.format("DD/MM/YYYY"),
            });
        }else{
            this.setState({toDate:''});
        }
    }
    render() {

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <Title title="Products" button="Add new" link="/products/new" />

                        <Container>

                            <div className="row">
                                <div className="col-sm-2">
                                    <SelectBox
                                        name="storeId"
                                        label="Store"
                                        value={this.state.storeId}
                                        options={this.state.stores}
                                        onChange={this.onChange.bind(this)}
                                        error={this.state.errStoreId}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <TextInput
                                        name="keyword"
                                        placeholder="Search products"
                                        text="Search"
                                        label="Keyword"
                                        value={this.state.keyword}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                               
                                <div className="col-sm-2">
                                    <SelectBox
                                        name="catLevel1"
                                        label="Product Category"
                                        value={this.state.catLevel1}
                                        options={this.state.level1}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <SelectBox
                                        name="status"
                                        label="Status"
                                        value={this.state.status}
                                        options={this.state.statuses}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <SelectBox
                                        name="catLevel2"
                                        label=" "
                                        value={this.state.catLevel2}
                                        options={this.state.level2}
                                        disabled={this.state.level2.length === 0}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <SelectBox
                                        name="catLevel3"
                                        label=" "
                                        value={this.state.catLevel3}
                                        options={this.state.level3}
                                        disabled={this.state.level3.length === 0}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-sm-2">
                                    <div className="row">
                                        <label>From Date</label>
                                    </div>
                                    <div className="row">
                                    <DatePicker
                                    selected ={this.state.fromDate === ""?"":moment(this.state.fromDate,"DD/MM/YYYY")}
                                    onChange={(e) => {this.setFromDate(e)}}
                                    name="fromDate"
                                    dateFormat="DD/MM/YYYY"
                                    maxDate={moment()}
                                    />
                                    </div>
                                    
                                </div>
                                <div className="col-sm-2">
                                    <div className="row">
                                        <label>To Date</label>
                                    </div>
                                    <div className="row">
                                    <DatePicker
                                    selected ={this.state.toDate === ""?"":moment(this.state.toDate,"DD/MM/YYYY")}
                                    onChange={(e) => {this.setToDate(e)}}
                                    name="toDate"
                                    dateFormat="DD/MM/YYYY"
                                    maxDate={moment()}
                                    />
                                    </div>
                                    
                                </div>
                                <div className="col-sm-1">
                                    <div className="form-group">
                                        <label htmlFor={this.props.name}>&nbsp;</label>
                                        <div className="input-group">
                                            <SubmitButton
                                                text="Filter"
                                                className="btn btn-full"
                                                onClick={this.filterResults}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-1">
                                    <div className="form-group">
                                        <label htmlFor={this.props.name}>&nbsp;</label>
                                        <div className="input-group">
                                            <SubmitButton
                                                text="Reset"
                                                className="btn btn-full btn-secondary"
                                                onClick={this.reset}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr />




                            <div className="row">
                                <div className="col-sm-12">

                                    {this.state.isLoading ?
                                        <Loader />
                                        :
                                        <div>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Barcode</th>
                                                        <th>Product Name</th>
                                                        <th>Price</th>
                                                        <th>Quantity</th>
                                                        <th>Status</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.products  ? this.state.products.map((item, index) => 
                                                        <tr key={index}>
                                                            <td><Link to={{pathname:`/products/${item.id}`,state:{storeId: `${this.state.storeId}`, keyword: `${this.state.keyword}`, fromDate: `${this.state.fromDate}`, toDate: `${this.state.toDate}`,status:`${this.state.status}`,page:`${this.state.page}`,catLevel1:`${this.state.catLevel1}`,catLevel2:`${this.state.catLevel2}`,catLevel3:`${this.state.catLevel3}`}}}>{item.barcode ? item.barcode : 'n/a'}</Link></td>
                                                            <td>{(item.title && item.title.length) > 50 ? `${item.title.substring(0, 50)}...` : item.title}</td>
                                                            <td>&pound;{item.price.toFixed(2)}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>{item.uploading ? <span className="red-warning">Uploading</span> : !item.onSale ? <span className="grey-warning">Unavailable</span> : item.quantity == 0 ? <span className="grey-warning">Sold Out</span> : <span className="yellow-warning">On Sale</span>}</td>
                                                            <td>{(item.onSale && item.handle != '' && item.handle != null) ? <a target="_blank" href={`https://cashgenerator.co.uk/products/${item.handle}`}><i className="fas fa-external-link-alt"></i></a> : ''}</td>
                                                        </tr>
                                                    ): "Loading......"}
                                                </tbody>
                                            </table>

                                            <div className="paging">
                                                <p>{this.state.count === 1 ? `${this.state.count} Result` : `${this.state.count} Results`}. Showing page {this.state.page} of {Math.ceil(this.state.count / this.state.size)}</p>
                                                <button className={this.state.page > 1 ? 'prev' : 'prev disabled'} disabled={this.state.page > 1 ? false : true} onClick={this.prevPage}><i className="fas fa-angle-left"></i></button>
                                                <button className={this.state.count > (this.state.size * this.state.page) ? 'next' : 'next disabled'} disabled={this.state.count > (this.state.size * this.state.page) ? false : true} onClick={this.nextPage}><i className="fas fa-angle-right"></i></button>
                                            </div>

                                        </div>
                                    }

                                </div>
                            </div>

                        </Container>

                    </div>
                </div>
            </div>
        )

    }

}

ProductsPage.propTypes = {
    firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        firstName: state.account.firstName,
    }
};

export default connect(mapStateToProps)(ProductsPage);