import React from 'react';
import { Switch, Route, Redirect, Router } from "react-router-dom";
import { connect } from 'react-redux';
import { isPrimitive } from 'util';
import PropTypes from 'prop-types';
import HomePage from '../containers/Home';
import OrdersPage from '../containers/Orders';
import OrderPage from '../containers/Order';
import ProductsPage from '../containers/Products';
import ProductPage from '../containers/Product';
import StoresPage from '../containers/Stores';
import StorePage from '../containers/Store';
import FiltersPage from '../containers/Filters';
import FilterPage from '../containers/Filter';
import LoginPage from '../containers/Login';
import UsersPage from '../containers/Users';
import UserPage from '../containers/User';
import ResetPage from '../containers/Reset';
import PasswordPage from '../containers/Password';
import FraudPage from '../containers/Fraud';
import ChatsPage from '../containers/Chats';
import ChatPage from '../containers/Chat';
import AccountsPage from '../containers/Accounts';
import AccountPage from '../containers/Account';
import TransactionPage from '../containers/Transaction';

class Routes extends React.Component {

    constructor(props) {
      super(props);
    }

    render() {

        const PrivateRoute = ({ component: Component, ...rest } )=> (
            <Route {...rest} render={(props) => (
              localStorage.getItem("userData") === null
                ? <Redirect to='/login' />
                : <Component {...props} />
            )}/>
        )

        return (
            <Switch>
                <PrivateRoute exact path="/" component={HomePage} />
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/reset" component={ResetPage} />
                <Route exact path="/reset/:id" component={PasswordPage} />
                <PrivateRoute exact path="/orders" component={OrdersPage} />
                <PrivateRoute exact path="/fraud" component={FraudPage} />
                <PrivateRoute exact path="/orders/:id/:status" component={OrderPage} />
                <PrivateRoute exact path="/products" component={ProductsPage} />
                <PrivateRoute exact path="/products/:id" component={ProductPage} />
                <PrivateRoute exact path="/stores" component={StoresPage} />
                <PrivateRoute exact path="/stores/:id" component={StorePage} />
                <PrivateRoute exact path="/filters" component={FiltersPage} />
                <PrivateRoute exact path="/filters/:id" component={FilterPage} />
                <PrivateRoute exact path="/users" component={UsersPage} />
                <PrivateRoute exact path="/users/:id" component={UserPage} />
                <PrivateRoute exact path="/chats" component={ChatsPage} />
                <PrivateRoute exact path="/chats/:id" component={ChatPage} />
                <PrivateRoute exact path="/accounts" component={AccountsPage} />
                <PrivateRoute exact path="/accounts/:id" component={AccountPage} />
                <PrivateRoute exact path="/transaction/:id" component={TransactionPage} />
            </Switch>
        )
    }

};

Routes.propTypes = {
    isLoggedIn: PropTypes.bool,
  };
  
  const mapStateToProps = (state) => {
    return {
      isLoggedIn: state.account.isLoggedIn,
    }
  };
  
  export default connect(mapStateToProps)(Routes);