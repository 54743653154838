import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Title } from '../../components/Title';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { TextInput, InputWithButton } from '../../components/TextInput';
import OrdersAPI from '../../api/OrdersAPI';
import { SelectBox } from '../../components/SelectBox';
import { Loader } from '../../components/Loader';
import UsersAPI from '../../api/UsersAPI';
import StoresAPI from '../../api/StoresAPI';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import DatePicker from 'react-datepicker'


class OrdersPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            page: 1,
            size: 30,
            count: 0,
            stores: [],
            storeId: '',
            keyword: '',
            fromDate: '',
            toDate:'',
            orders: [],
            userId: '',
            userLevel: 'user',
            status: '',
            statuses: [{
                value: '',
                label: 'Any'
            }, {
                value: 'pending',
                label: 'Pending'
            }, {
                value: 'ready',
                label: 'Ready for Collection'
            }, {
                value: 'collected',
                label: 'Collected'
            }, {
                value: 'fulfilled',
                label: 'Fulfilled'
            }, {
                value: 'refunded',
                label: 'Refunded'
            }, {
                value: 'partial-refund',
                label: 'Partially Refunded'
            }],
            errStoreId: '',
            errStatus: '',
            searchValues:this.props.location.state,
        }

        this.nextPage = this.nextPage.bind(this);
        this.loadOrders = this.loadOrders.bind(this);
        this.reset = this.reset.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.filterResults = this.filterResults.bind(this);

    }

    reset() {
        this.setState({
            keyword: '',
            status: '',
            fromDate: '',
            toDate : '',
            page: 1,
        }, () => {
            this.loadOrders();
        })
    }

    loadAllStores() {
        StoresAPI.getStores((data) => {
            var _arr = [];
            _arr.push({
                value: 'all',
                label: 'All Stores',
            });
            for (var i = 0; i < data.length; i++) {
                if (i === 0) {
                    if(this.state.storeId ==='null' || this.state.storeId ==='') {
                        this.setState({
                            storeId: 'all'
                        }, () => {
                            this.loadOrders();
                        })
                    }else {
                        this.loadOrders();
                    }
                    
                }
                _arr.push({
                    value: data[i].id,
                    label: data[i].title,
                });
            }
            this.setState({
                stores: _arr
            });
        });
    }

    loadUserStores() {
        UsersAPI.getUserStores(this.state.userId, (data) => {
            var _arr = [];
            for (var i = 0; i < data.length; i++) {
                if (i === 0) {
                    this.setState({
                        storeId: data[i].id
                    }, () => {
                        this.loadOrders();
                    })
                }
                _arr.push({
                    value: data[i].id,
                    label: data[i].title,
                });
            }
            this.setState({
                stores: _arr
            });
        });
    }

    componentDidMount() {
        if(this.state.searchValues !== undefined && this.state.searchValues !== null){
            if(this.state.searchValues.toDate !== undefined) {
                this.state.toDate = this.state.searchValues.toDate;
                this.state.fromDate = this.state.searchValues.fromDate;
                this.state.storeId = this.state.searchValues.storeId;
                this.state.keyword = this.state.searchValues.keyword;
                this.state.status = this.state.searchValues.status;
                this.state.page = this.state.searchValues.page;
            }
        }
        
        UsersAPI.getUserData((data) => {
            this.setState({
                userLevel: data.userLevel,
                userId: data.id,
            }, () => {
                if (this.state.userLevel === 'admin') {
                    this.loadAllStores();
                } else {
                    this.loadUserStores();
                }
            });
        })
    }

    filterResults() {
        this.setState({
            page: 1,
        }, () => {
            this.loadOrders();
        })
    }

    loadOrders() {

        this.setState({
            isLoading: true
        });

        window.scrollTo(0, 0);

        let _options = {
            store: this.state.storeId,
            keyword: this.state.keyword,
            status: this.state.status,
            fromDate: this.state.fromDate,
            toDate : this.state.toDate,
            page: this.state.page,
        };

        OrdersAPI.getOrders(_options, (data) => {
            this.setState({
                isLoading: false,
                count: data.count,
                orders: data.results
            });
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    prevPage() {
         var page = this.state.page - 1;
        this.setState({
            page: page
        }, () => {
            this.loadOrders();
        });
    }

    nextPage() {
        var page = this.state.page + 1;
        this.setState({
            page: page
        }, () => {
            this.loadOrders();
        });
    }
    setFromDate(e) {
        if(e!=="" && e!==null){
            this.setState({
                fromDate: e.format("DD/MM/YYYY"),
            });
        }else{
            this.setState({fromDate:''});
        }
    }
    setToDate(e) {
        if(e !==""  && e!== null){
            this.setState({
                toDate: e.format("DD/MM/YYYY"),
            });
        }else {
            this.setState({toDate:''});
        }
        
    }

    render() {

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <Title title="Orders" />

                        <Container>

                            <div className="row">
                                <div className="col-sm-3">
                                    <SelectBox
                                        name="storeId"
                                        label="Store"
                                        value={this.state.storeId}
                                        options={this.state.stores}
                                        onChange={this.onChange.bind(this)}
                                        error={this.state.errStoreId}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <TextInput
                                        name="keyword"
                                        placeholder="Search orders"
                                        text="Search"
                                        label="Keyword"
                                        value={this.state.keyword}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>

                                <div className="col-sm-3">
                                    <SelectBox
                                        name="status"
                                        label="Status"
                                        value={this.state.status}
                                        options={this.state.statuses}
                                        onChange={this.onChange.bind(this)}
                                        error={this.state.errStatus}
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-sm-2">
                                    <div className="row">
                                        <label>From Date</label>
                                    </div>
                                    <div className="row">
                                    <DatePicker
                                    selected ={this.state.fromDate === ""?"":moment(this.state.fromDate,"DD/MM/YYYY")}
                                    onChange={(e) => {this.setFromDate(e)}}
                                    name="fromDate"
                                    dateFormat="DD/MM/YYYY"
                                    maxDate={moment()}
                                    />
                                    </div>
                                    
                                </div>
                                <div className="col-sm-2">
                                    <div className="row">
                                        <label>To Date</label>
                                    </div>
                                    <div className="row">
                                    <DatePicker
                                    selected ={this.state.toDate === ""?"":moment(this.state.toDate,"DD/MM/YYYY")}
                                    onChange={(e) => {this.setToDate(e)}}
                                    name="toDate"
                                    dateFormat="DD/MM/YYYY"
                                    maxDate={moment()}
                                    />
                                    </div>
                                
                                </div>
                                <div className="col-sm-1">
                                    <div className="form-group">
                                        <label htmlFor={this.props.name}>&nbsp;</label>
                                        <div className="input-group">
                                            <SubmitButton
                                                text="Filter"
                                                className="btn btn-full"
                                                onClick={this.filterResults}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-1">
                                    <div className="form-group">
                                        <label htmlFor={this.props.name}>&nbsp;</label>
                                        <div className="input-group">
                                            <SubmitButton
                                                text="Reset"
                                                className="btn btn-full btn-secondary"
                                                onClick={this.reset}
                                            />
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                            
                            <hr />

                            <div className="row">
                                <div className="col-sm-12">

                                    {this.state.isLoading ?
                                        <Loader />
                                        :
                                        <div>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Order Number</th>
                                                        <th>Order Date</th>
                                                        <th>Customer</th>
                                                        <th>Order Total</th>
                                                        <th>Fulfilment</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.orders && this.state.orders.length >0 ? this.state.orders.map((item, index) =>
                                                        <tr key={index}>
                                                             <td><Link to={{pathname: `/orders/${item.id}/${item.fulfillment_status ==''?'null':item.fulfillment_status}`,state: {storeId: `${this.state.storeId}`, keyword: `${this.state.keyword}`, fromDate: `${this.state.fromDate}`,toDate: `${this.state.toDate}`,status:`${this.state.status}`,page:`${this.state.page}`}}}>{item.order_number}</Link></td>
                                                            <td>{moment(item.created_at).format("ddd DD MMM YY @ HH:mm")}</td>
                                                            <td>{item.email}</td>
                                                            <td>&pound;{(item.total_price).toFixed(2)}</td>
                                                            <td>{item.fulfilmentMethod == 'collection' ? 'Collection' : 'Delivery'}</td>
                                                            <td>{item.fulfillment_status == 'fulfilled' ? <span className="grey-warning">Fulfilled</span> : item.fulfillment_status == 'collected' ? <span className="grey-warning">Collected</span> : item.fulfillment_status == 'ready' ? <span className="yellow-warning">Ready for Collection</span> : item.fulfillment_status == 'refunded'? <span className="yellow-warning">Refunded</span>: item.fulfillment_status == 'partial-refund'? <span className="yellow-warning">Partial Refunded</span>: <span className="yellow-warning">Pending</span>}</td>
                                                        </tr>
                                                    ) :
                                                        <tr>
                                                            <td colSpan="6" className="text-center">You have no orders.</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                            <div className="paging">
                                                <p>{this.state.count === 1 ? `${this.state.count} Result` : `${this.state.count} Results`}. Showing page {this.state.page} of {Math.ceil(this.state.count / this.state.size)}</p>
                                                <button className={this.state.page > 1 ? 'prev' : 'prev disabled'} disabled={this.state.page > 1 ? false : true} onClick={this.prevPage}><i className="fas fa-angle-left"></i></button>
                                                <button className={this.state.count > (this.state.size * this.state.page) ? 'next' : 'next disabled'} disabled={this.state.count > (this.state.size * this.state.page) ? false : true} onClick={this.nextPage}><i className="fas fa-angle-right"></i></button>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>

                        </Container>

                    </div>
                </div>
            </div>
        )

    }

}

OrdersPage.propTypes = {
    firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        firstName: state.account.firstName,
    }
};

export default connect(mapStateToProps)(OrdersPage);