import APIUtils from "./APIUtils";

async function apiRequest({ endpoint = '', method = 'GET', payload = null }, cb) {
    const url = `${APIUtils.getBaseUrl()}${endpoint}`
    APIUtils.getHeaders(async (headers) => {
        const response = await fetch(url, {
            method,
            headers,
            ...(payload != null ? { body: JSON.stringify(payload) } : {}),
        });
    
        const statusCode = await response.status;
        if (statusCode === 200) 
        {
            const json = await response.json();
            cb(json);
        } 
        else 
        {
            cb(false);
        }
    });
}



export default apiRequest;