import apiRequest from '.';
import APIUtils from './APIUtils';
import axios from 'axios';

class ProductsAPI {

    getProducts(options = {}, cb) {
        let url = "products?1=1";
        if (options.store) {
            url += `&store=${options.store}`;
        }
        if (options.keyword) {
            url += `&keyword=${options.keyword}`;
        }
        if (options.fromDate) {
            url += `&fromDate=${options.fromDate}`;
        }
        if (options.toDate) {
            url += `&toDate=${options.toDate}`;
        }
        if (options.status) {
            url += `&status=${options.status}`;
        }
        if (options.level1) {
            url += `&l1=${options.level1}`;
        }
        if (options.level2) {
            url += `&l2=${options.level2}`;
        }
        if (options.level3) {
            url += `&l3=${options.level3}`;
        }
        if (options.page) {
            url += `&page=${options.page}`;
        }
        
        apiRequest({ endpoint: url }, cb);
    }

    getProduct(id, cb) {
        apiRequest({ endpoint: `products/${id}` }, cb);
    }

    deleteProduct(id, cb) {
        apiRequest({ endpoint: `products/${id}`, method: 'DELETE' }, cb);
    }

    addProduct(payload, cb) {
        apiRequest({ endpoint: `products`, method: 'POST', payload }, cb);
    }

    updProduct(id, payload, cb) {
        apiRequest({ endpoint: `products/${id}`, method: 'PUT', payload }, cb);
    }

    checkProduct(payload, cb) {
        apiRequest({ endpoint: `products/check`, method: 'POST', payload }, cb);
    }

    getCollections(id, cb) {
        apiRequest({ endpoint: `collections/${id}` }, cb);
    }

    getCollectionFilters(id, cb) {
        apiRequest({ endpoint: `filters/collection/${id}` }, cb);
    }

    getFilters(cb) {
        apiRequest({ endpoint: `filters` }, cb);
    }

    getFilter(id, cb) {
        apiRequest({ endpoint: `filters/${id}` }, cb);
    }

    addFilter(payload, cb) {
        apiRequest({ endpoint: `filters`, method: 'POST', payload }, cb);
    }

    updFilter(id, payload, cb) {
        apiRequest({ endpoint: `filters/${id}`, method: 'PUT', payload }, cb);
    }

    addAttachment(id, payload, cb) {

        let user = JSON.parse(localStorage.getItem('userData'));
        axios.post(APIUtils.getBaseUrl() + `attach`, payload, {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${payload._boundary}`
            }
        })
            .then((response) => {
                console.log("Response: ", response);
                cb(response);
            }).catch((error) => {
                console.log("Err: ", error);
                cb(error);
            });

    }

}

export default new ProductsAPI();