import apiRequest from '.';
import APIUtils from './APIUtils';

class OrdersAPI {

    getOrders(options, cb) {
        let url = 'orders?1=1';
        if (options.store === 'all') {
            url += '&admin-view=true';
        } else {
            url += `&store=${options.store}`;
        }
        if (options.keyword) {
            url += `&keyword=${options.keyword}`;
        }
        if (options.fromDate) {
            url += `&fromDate=${options.fromDate}`;
        }
        if (options.toDate) {
            url += `&toDate=${options.toDate}`;
        }
        if (options.status) {
            url += `&status=${options.status}`;
        }
        if (options.page) {
            url += `&page=${options.page}`;
        }
        if (options.risk) {
            url += `&risk=${options.risk}`;
        }
        apiRequest({ endpoint: url }, cb);

    }

    getOrder(id, cb) {
        apiRequest({ endpoint: `orders/${id}` }, cb);
    }

    sendOrderMail(id, type, cb) {
        apiRequest({ endpoint: `orders/mail/${id}?type=${type}` }, cb);
    }

    updOrder(id, payload, cb) {
        apiRequest({ endpoint: `orders/${id}`, method: 'PUT', payload }, cb);
    }

    blockPayments(id, cb) {
        apiRequest({ endpoint: `orders/block/${id}`, method: 'PUT', id }, cb);
    }

    updOrderFraud(id, payload, cb) {
        apiRequest({ endpoint: `orders/fraud/${id}`, method: 'PUT', payload }, cb);
    }

    updCollectionReady(id, payload, cb) {
        apiRequest({ endpoint: `orders/collection/${id}`, method: 'POST', id }, cb);
    }

}

export default new OrdersAPI();