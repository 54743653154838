import apiRequest from '.';
import APIUtils from './APIUtils';

class AccountsAPI {

    getAccounts(start, end, cb) {
        apiRequest({ endpoint: `accounts?start=${start}&end=${end}` }, cb);
    }

    getAccount(id, start, end, cb) {
        apiRequest({ endpoint: `accounts/${id}?start=${start}&end=${end}` }, cb);
    }

    addTransaction(payload, cb) {
        apiRequest({ endpoint: `accounts`, method: 'POST', payload }, cb);
    }

    exportAccounts(payload, cb) {
        apiRequest({ endpoint: `accounts/export`, method: 'POST', payload }, cb);
    }

    delTransaction(id, cb) {
        apiRequest({ endpoint: `accounts/${id}`, method: 'DELETE', id }, cb);
    }

}

export default new AccountsAPI();