import React, { Component } from 'react';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { Title } from '../../components/Title';
import { Divider } from '../../components/Divider';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { TextInput, TextArea, CurrencyInput } from '../../components/TextInput';
import { SelectBox } from '../../components/SelectBox';
import UsersAPI from '../../api/UsersAPI';
import StoresAPI from '../../api/StoresAPI';

class UserPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            firstName: '',
            lastName: '',
            email: '',
            companyName: '',
            companyNumber: '',
            userLevel: '',
            userLevels: [{
                value: '',
                label: 'Select...',
            }, {
                value: 'user',
                label: 'User',
            }, {
                value: 'admin',
                label: 'Admin',
            }],
            stores: [],
            userStores: [],
            storeId: '',
            errStoreId: '',
            password: '',
            errFirstName: '',
            errLastName: '',
            errEmail: '',
            errUserLevel: '',
            errCompanyName: '',
            errCompanyNumber: '',
            errPassword: '',
            welcomeSent: false,
            liveChat: false,
            accounting: false,
        };
        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
        this.addStore = this.addStore.bind(this);
        this.delStore = this.delStore.bind(this);
        this.delete = this.delete.bind(this);
        this.sendWelcome = this.sendWelcome.bind(this);

    }

    componentDidMount() {
        this.loadStores();
        if (this.state.id !== 'new') {
            this.loadUser();
            this.loadUserStores();
        }
    }

    loadUser() {
        UsersAPI.getUser(this.state.id, (data) => {
            console.log("user", data);
            this.setState({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                companyName: data.companyName,
                companyNumber: data.companyNumber,
                userLevel: data.userLevel,
                liveChat: data.liveChat,
                accounting: data.accounting,
            });
        })
    }

    loadUserStores() {
        UsersAPI.getUserStores(this.state.id, (data) => {
            this.setState({
                userStores: data,
                storeId: '',
            });
        });
    }

    addStore() {
        let payload = {
            userId: this.state.id,
            storeId: this.state.storeId,
        };

        UsersAPI.addUserStore(payload, (data) => {
            this.loadUserStores();
        });

    }

    delStore(id) {
        UsersAPI.delUserStore(this.state.id, id, (data) => {
            this.loadUserStores();
        })
    }

    loadStores() {
        StoresAPI.getStores((data) => {
            var _arr = [];
            _arr.push({
                value: '',
                label: 'Select...',
            });
            for (var i = 0; i < data.length; i++) {
                _arr.push({
                    value: data[i].id,
                    label: data[i].title,
                });
            }
            this.setState({
                stores: _arr
            });
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
            errFirstName: '',
            errLastName: '',
            errEmail: '',
            errUserLevel: '',
            errCompanyName: '',
            errCompanyNumber: '',
            errPassword: '',
        });
    }

    save() {

        var _ok = true;

        if (this.state.firstName === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errFirstName: 'Please enter the first name'
            });
        }
        if (this.state.lastName === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errLastName: 'Please enter the last name'
            });
        }
        if (this.state.email === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errEmail: 'Please enter the email address'
            });
        }
        if (this.state.userLevel === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errUserLevel: 'Please select a user level'
            });
        }
        if (this.state.companyName === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errCompanyName: 'Please enter the company name'
            });
        }
        if (this.state.companyNumber === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errCompanyNumber: 'Please enter the company number'
            });
        }

        if (_ok) {

            let payload = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                companyName: this.state.companyName,
                companyNumber: this.state.companyNumber,
                userLevel: this.state.userLevel,
                liveChat: this.state.liveChat,
                accounting: this.state.accounting,
            }

            if (this.state.password !== '') {
                payload.password = this.state.password;
            }

            if (this.state.id === 'new') {
                UsersAPI.addUser(payload, (data) => {
                    this.props.history.push(`/users/${data.id}`);
                });
            } else {
                // Update product
                UsersAPI.updUser(this.state.id, payload, (data) => {
                    this.props.history.push(`/users`);
                });
            }

        }

    }

    cancel() {
        this.props.history.push('/users');
    }

    delete() {
        UsersAPI.delUser(this.state.id, (data) => {
            this.props.history.push('/users');
        })
    }

    sendWelcome() {
        UsersAPI.sendWelcome(this.state.id, (data) => {
            this.setState({
                welcomeSent: true
            });
        });
    }

    render() {

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <NarrowContainer>

                            <BackButton url="/users" title="Back" />
                            <Title title={this.state.id === 'new' ? "New User" : "Edit User"} />

                            <FormContainer title="User Information"
                                description={`Please add the details for this store.`}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="firstName"
                                            type="text"
                                            label="First Name"
                                            value={this.state.firstName}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errFirstName}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="lastName"
                                            type="text"
                                            label="Last Name"
                                            value={this.state.lastName}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errLastName}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="email"
                                            type="text"
                                            label="Email Address"
                                            value={this.state.email}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errEmail}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <SelectBox
                                            name="userLevel"
                                            label="User Level"
                                            value={this.state.userLevel}
                                            options={this.state.userLevels}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errUserLevel}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="companyName"
                                            type="text"
                                            label="Company Name"
                                            value={this.state.companyName}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errCompanyName}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="companyNumber"
                                            type="text"
                                            label="Company Number"
                                            value={this.state.companyNumber}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errCompanyNumber}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        {(this.state.id !== 'new' && !this.state.welcomeSent) &&
                                            <SubmitButton
                                                text="Send Welcome Email"
                                                className="btn btn-secondary"
                                                onClick={(e) => { this.sendWelcome() }}
                                            />
                                        }
                                        {this.state.welcomeSent &&
                                            <p style={{ fontSize: '12px' }}>Welcome email sent.</p>
                                        }
                                    </div>
                                </div>

                            </FormContainer>

                            <FormContainer title="User Settings"
                                description={`Configuration settings for this user.`}>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <SwitchButton
                                            label="Live Chat Access"
                                            name="liveChat"
                                            value={this.state.liveChat}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <SwitchButton
                                            label="Accounting Access"
                                            name="accounting"
                                            value={this.state.accounting}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                            </FormContainer>

                            <Divider />

                            {this.state.id !== 'new' &&
                                <Divider />
                            }

                            {this.state.id !== 'new' &&
                                <FormContainer title="User Stores"
                                    description={``}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Store</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.userStores.length === 0 &&
                                                        <tr>
                                                            <td colSpan="2" className="text-center">No stores added for this user.</td>
                                                        </tr>
                                                    }
                                                    {this.state.userStores.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{item.title}</td>
                                                            <td className="text-right"><span className="delete-item" onClick={(e) => { if (window.confirm('Are you sure you wish to delete this user?')) this.delStore(item.id) }}><i style={{ marginTop: 5 }} className="far fa-times-circle"></i></span></td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <SelectBox
                                                name="storeId"
                                                label="Store"
                                                value={this.state.storeId}
                                                options={this.state.stores}
                                                asterix={true}
                                                onChange={this.onChange.bind(this)}
                                                error={this.state.errStoreId}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <SubmitButton
                                                text="Add Store"
                                                className="btn btn-secondary"
                                                onClick={this.addStore}
                                            />
                                        </div>
                                    </div>
                                </FormContainer>
                            }

                            <Divider />

                            <div className="row">
                                <div className="col-sm-4">
                                    {this.state.id !== 'new' &&
                                        <SubmitButton
                                            text="Delete User"
                                            className="btn btn-secondary"
                                            onClick={(e) => { if (window.confirm('Are you sure you wish to delete this user?')) this.delete() }}
                                        />
                                    }
                                </div>
                                <div className="col-sm-4" style={{ paddingBottom: 60 }}>
                                    &nbsp;
                                </div>
                                <div className="col-sm-4 text-right">
                                    <SubmitButton
                                        text="Cancel"
                                        className="btn btn-secondary"
                                        onClick={this.cancel}
                                    />
                                    <SubmitButton
                                        text="Save User"
                                        className="btn"
                                        onClick={this.save}
                                    />
                                </div>
                            </div>

                        </NarrowContainer>
                    </div>
                </div>
            </div>
        )

    }

}

export default UserPage;