import apiRequest from '.';
import APIUtils from './APIUtils';

class ChannelAPI {

    getChannels(cb) {
        apiRequest({ endpoint: `channels` }, cb);
    }

}

export default new ChannelAPI();