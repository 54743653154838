import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Tabs } from '../Tabs';

class AccountsMenu extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Tabs
            tabs={[{
                url: `/accounts`,
                label: 'Accounts'
            },{
                url: `/accounts/history`,
                label: 'History'
            }]} />
        )
    }
};

export default withRouter(AccountsMenu);