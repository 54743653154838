import apiRequest from '.';
import APIUtils from './APIUtils';

class StoresAPI {

    getStores(cb) {
        apiRequest({ endpoint: `stores` }, cb);
    }

    getStore(id, cb) {
        apiRequest({ endpoint: `stores/${id}` }, cb);
    }

    deleteStore(id, cb) {
        apiRequest({ endpoint: `stores/${id}`, method: 'DELETE' }, cb);
    }

    addStore(payload, cb) {
        apiRequest({ endpoint: `stores`, method: 'POST', payload }, cb);
    }

    updStore(id, payload, cb) {
        apiRequest({ endpoint: `stores/${id}`, method: 'PUT', payload }, cb);
    }

}

export default new StoresAPI();