import apiRequest from '.';
import APIUtils from './APIUtils';

class LookupAPI {

    getResults(keyword, cb) {
        apiRequest({ endpoint: `lookup/results?keyword=${keyword}` }, cb);
    }

    getProduct(id, cb) {
        apiRequest({ endpoint: `lookup/product/${id}` }, cb);
    }

    getGeoLocation(location, cb) {
        apiRequest({ endpoint: `geocode/${location}` }, cb);
    }

}

export default new LookupAPI();