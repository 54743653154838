import apiRequest from '.';
import APIUtils from './APIUtils';

class ChatsAPI {

    getChats(options = {}, cb) {
        let url = 'chat?1=1';
        if (options.store === 'all') {
            url += '&admin-view=true';
        } else {
            url += `&store=${options.store}`;
        }
        url += `&status=${options.status}`;
        apiRequest({ endpoint: url }, cb);
    }

    getchat(id, cb) {
        apiRequest({ endpoint: `chat/${id}` }, cb);
    }

    addChat(payload, cb) {
        apiRequest({ endpoint: `chat`, method: 'POST', payload }, cb);
    }

}

export default new ChatsAPI();