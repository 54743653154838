import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { signOut } from '../../actions/account';
import UsersAPI from '../../api/UsersAPI';
import { SubmitButton } from '../../components/Button';
import { runInThisContext } from 'vm';

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            firstname: '',
            lastname: '',
            email: '',
            keyword: '',
            results: []
        }

        this.logOut = this.logOut.bind(this);
        this.newCustomer = this.newCustomer.bind(this);
        this.goTo = this.goTo.bind(this);

    }

    componentDidMount() {
        UsersAPI.getUserData((data) => {
            console.log(data);
            this.setState({
                firstname: data.firstName,
                lastname: data.lastName,
                email: data.emailAddress
            });
        })
    }

    logOut() {
        this.props.dispatch(signOut());
        localStorage.removeItem('userData');
        this.props.history.push('/');
    }

    goTo(link) {
        this.setState({
            keyword: '',
            results: []
        }, () => {
            this.props.history.push(link);
        });
    }


    newCustomer() {
        this.props.history.push('/customers/new');
    }

    onFieldChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            keyword: value
        }, () => {
            if (value.length >= 2) {
                /*ConsoleAPI.getSearch(value, (data) => {
                    this.setState({
                        results: data
                    });
                });*/
            } else {
                this.setState({
                    results: []
                })
            }
        });
    }

    render() {
        return (

            <header>
                <div className="left-column logo">
                    <a href="/"><img src="/assets/img/CashGenerator.png" /></a>
                </div>
                <div className="right-column">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-4 header-search">
                                &nbsp;
                            </div>
                            <div className="col-sm-8 text-right">
                                <div>{`${this.state.firstname} ${this.state.lastname}`} (<a onClick={this.logOut}>Sign Out</a>)</div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    };

}

Header.propTypes = {
    isLoggedIn: PropTypes.bool,
    userName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.account.isLoggedIn,
        userName: state.account.userName,
    }
};

export default connect(mapStateToProps)(Header);