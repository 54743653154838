import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Route, Redirect, Link} from 'react-router-dom';
import { SubmitButton } from '../../components/Button';
import { TextInput } from '../../components/TextInput';
import { Container } from '../../components/Container';
import UsersAPI from '../../api/UsersAPI';

class LoginPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            loginEmail: '',
            loginPassword: '',
            version: ''
        };

        this.handleLogin = this.handleLogin.bind(this);

    }
    
    componentDidMount() {
        import('../../../package.json').then(({ version }) => { 
            this.setState({ version });
        });
    }

    handleLogin() {

        this.setState({
            isLoading: true
        });

        let payload = {
            email: this.state.loginEmail,
            password: this.state.loginPassword,
        };

        
        UsersAPI.auth(payload, (data) => {
            if (data) {
                if (data.id) {
                    localStorage.setItem('userData', JSON.stringify(data));
                    this.props.history.push('/');
                } else {
                    this.setState({
                        error: "Login failed.  Please try again.",
                        isLoading: false
                    });
                }
            } else {
                this.setState({
                    error: "Login failed.  Please try again.",
                    isLoading: false
                });
            }
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
            error: '',
        });
    }

    render() {

        return (

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-sm-12 login">

                        <p><img src="/assets/img/CashGenerator.png" /></p>
                        
                        <Container>

                            <div className="row">
                                <div className="col-sm-12">
                                    <TextInput 
                                        name="loginEmail"
                                        type="email"
                                        label="Email Address"
                                        placeholder="Enter email address..."
                                        value={this.state.loginEmail}
                                        maxLength={45}
                                        asterix={true}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <TextInput 
                                        name="loginPassword"
                                        type="password"
                                        label="Password"
                                        placeholder="Enter password..."
                                        value={this.state.loginPassword}
                                        maxLength={50}
                                        asterix={true}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                            </div>
                            {this.state.error &&
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <p className="error">{this.state.error}</p>
                                </div>
                            </div>
                            }
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <p className="text-center" style={{paddingBottom: 10, fontSize: 12}}><Link to={'/reset'}>Forgotten password?</Link></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <SubmitButton
                                        text={this.state.isLoading ? 'Verifying...' : 'Login'}
                                        disabled={this.state.isLoading}
                                        className="btn"
                                        onClick={this.handleLogin}
                                    />
                                </div>
                            </div>
                            
                        </Container>
                        <p style={{textAlign: 'center'}}><small>Version: {this.state.version}</small></p>

                    </div>
                </div>
            </div>

        )
    }

}

LoginPage.propTypes = {
    isLoggedIn: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.account.isLoggedIn,
    }
};

export default connect(mapStateToProps)(LoginPage);