import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Title } from '../../components/Title';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { TextInput, InputWithButton } from '../../components/TextInput';
import OrdersAPI from '../../api/OrdersAPI';
import { SelectBox } from '../../components/SelectBox';
import { Loader } from '../../components/Loader';
import UsersAPI from '../../api/UsersAPI';
import StoresAPI from '../../api/StoresAPI';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import AccountsAPI from '../../api/AccountsAPI';

class AccountsPage extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: true,
            accounts: [],
            userId: '',
            start: moment().add(-7, "days").format("YYYY-MM-DD"),
            end: moment().add(-1, "day").format("YYYY-MM-DD"),
            userLevel: 'user',
            filterBalance: 0,
            toggleAll: true,
            isExporting: false,
        }

        this.loadAccounts = this.loadAccounts.bind(this);
        this.toggleChecked = this.toggleChecked.bind(this);
        this.exportAccounts = this.exportAccounts.bind(this);

    }

    componentDidMount() {
        this.checkUserIsAdmin();
        UsersAPI.getUserData((data) => {
            console.log(data);
            this.setState({
                userLevel: data.userLevel,
                userId: data.id,
            });
            this.loadAccounts();
        })
    }

    loadAccounts() {
        this.setState({
            isLoading: true
        }, () => {
            AccountsAPI.getAccounts(this.state.start, this.state.end, (data) => {

                var _arr = [];
                for (var i = 0; i < data.length; i++) {
                    _arr.push({
                        id: data[i].id,
                        title: data[i].title,
                        groupName: data[i].groupName,
                        openingBalance: data[i].openingBalance,
                        closingBalance: data[i].closingBalance,
                        checked: true //data[i].closingBalance > 0 ? true : false
                    });
                }
                this.setState({
                    isLoading: false,
                    accounts: _arr
                });

            });
        });
    }

    checkUserIsAdmin() {
        UsersAPI.getUserData((data) => {
            UsersAPI.getUser(data.id, (_usr) => {
                if (_usr.userLevel !== 'admin') {
                    this.props.history.push(`/`);
                }
            });
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    toggleChecked(id) {
        let allChecked = true;
        const newAccountsList = this.state.accounts.map((account, accindex) => {
            if ((account.checked === false && account.closingBalance > 0 && id !== account.id) || (id === account.id && account.checked === true)) {
                allChecked = false;
            }
            if (id !== account.id) return account;
            return { ...account, checked: !account.checked };
        });
        this.setState({
            accounts: newAccountsList,
            toggleAll: allChecked,
        });
    }

    toggleAll() {
        if (this.state.toggleAll) {
            const newAccountsList = this.state.accounts.map((account, accindex) => {
                return { ...account, checked: false };
            });
            this.setState({
                toggleAll: false,
                accounts: newAccountsList
            });
        } else {
            const newAccountsList = this.state.accounts.map((account, accindex) => {
                return { ...account, checked: true };
            });
            this.setState({
                toggleAll: true,
                accounts: newAccountsList
            });
        }
    }

    exportAccounts() {

        this.setState({
            isExporting: true
        });

        let _stores = [];
        for (var i = 0; i < this.state.accounts.length; i++) {
            if (this.state.accounts[i].checked) {
                _stores.push(this.state.accounts[i].id);
            }
        }

        let payload = {
            start: this.state.start,
            end: this.state.end,
            stores: _stores
        }

        AccountsAPI.exportAccounts(payload, (data) => {
            this.setState({
                isExporting: false
            });
            //window.open(data.url, "_blank");
            this.loadAccounts();
        });

    }

    render() {

        const sum = this.state.accounts.filter(d => d.closingBalance > 0 && d.checked === true).map(v => v.closingBalance).reduce((sum, current) => parseFloat(sum) + parseFloat(current), 0);
        const payments = this.state.accounts.filter(d => d.checked === true).length;

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <Title title="Accounts" />

                        <Container>

                            <div className="row">
                                <div className="col-sm-2">
                                    <TextInput
                                        name="start"
                                        type="date"
                                        label="Period From"
                                        value={this.state.start}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <TextInput
                                        name="end"
                                        type="date"
                                        label="Period To"
                                        value={this.state.end}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="col-sm-1">
                                    <div className="form-group">
                                        <label htmlFor={this.props.name}>&nbsp;</label>
                                        <div className="input-group">
                                            <SubmitButton
                                                text="Filter"
                                                className="btn btn-full"
                                                onClick={this.loadAccounts}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <label htmlFor={this.props.name}>&nbsp;</label>
                                        <div className="input-group">
                                            <SubmitButton
                                                text={this.state.isExporting ? 'Processing...' : `Process (${payments}) Payment(s)`}
                                                className={this.state.isExporting ? 'btn btn-full btn-secondary-disabled' : 'btn btn-full btn-secondary'}
                                                onClick={(e) => { if (window.confirm(`Are you sure you wish to process the selected ${payments} payment(s)?`)) this.exportAccounts() }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">&nbsp;</div>
                                <div className="col-sm-2">
                                    <SelectBox
                                        name="filterBalance"
                                        label=" "
                                        value={this.state.filterBalance}
                                        options={[
                                            {
                                                value: 0,
                                                label: 'All'
                                            },
                                            {
                                                value: 1,
                                                label: 'Positive Balances Only'
                                            },
                                            {
                                                value: 2,
                                                label: 'Negative Balances Only'
                                            }
                                        ]}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                            </div>

                            <hr />

                            <div className="row">
                                <div className="col-sm-12">

                                    {this.state.isLoading ?
                                        <Loader />
                                        :
                                        <div>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th width="35%">
                                                            <div className="account-check">
                                                                <input type="checkbox" checked={this.state.toggleAll} onChange={() => this.toggleAll()} />
                                                            </div>
                                                            <div className="account-name">
                                                                Group/Store Name
                                                            </div>
                                                        </th>
                                                        <th width="25%">Store</th>
                                                        <th width="20%">Opening Balance</th>
                                                        <th width="20%">Closing Balance</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.accounts.filter(d => (parseInt(this.state.filterBalance) === 0) || (parseInt(this.state.filterBalance) === 1 && d.closingBalance > 0) || (parseInt(this.state.filterBalance) === 2 && d.closingBalance < 0)).map((item, index) =>
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="account-check">
                                                                    <input type="checkbox" checked={item.checked} onChange={() => this.toggleChecked(item.id)} />
                                                                </div>
                                                                <div className="account-name">
                                                                    <Link to={`/accounts/${item.id}`}>{(item.groupName !== '' && item.groupName !== null) ? item.groupName : item.title}</Link>
                                                                </div>
                                                            </td>
                                                            <td><Link to={`/accounts/${item.id}`}>{(item.groupName !== '' && item.groupName !== null) ? item.title : ''}</Link></td>
                                                            <td>{item.openingBalance > 0 ? <span className="yellow-warning">&pound;{item.openingBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : item.openingBalance < 0 ? <span className="red-warning">&pound;{item.openingBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : <span className="grey-warning">&pound;{item.openingBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>}</td>
                                                            <td>{item.closingBalance > 0 ? <span className="yellow-warning">&pound;{item.closingBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : item.closingBalance < 0 ? <span className="red-warning">&pound;{item.closingBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : <span className="grey-warning">&pound;{item.closingBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>}</td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td>&nbsp;</td>
                                                        <td><strong>&pound;{sum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                                    </tr>
                                                    {this.state.accounts.length === 0 &&
                                                        <tr>
                                                            <td colSpan="4" className="text-center">There are no accounts.</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    }

                                </div>
                            </div>

                        </Container>

                    </div>
                </div>
            </div>
        )

    }

}

AccountsPage.propTypes = {
    firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        firstName: state.account.firstName,
    }
};

export default connect(mapStateToProps)(AccountsPage);