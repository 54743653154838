import React, { Component } from 'react';
import moment from 'moment';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { Title } from '../../components/Title';
import { Divider } from '../../components/Divider';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { TextInput, TextArea, CurrencyInput } from '../../components/TextInput';
import { SelectBox } from '../../components/SelectBox';
import UsersAPI from '../../api/UsersAPI';
import StoresAPI from '../../api/StoresAPI';
import AccountsAPI from '../../api/AccountsAPI';

class TransactionPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            title: '',
            transactionDate: moment().format("YYYY-MM-DD"),
            description: '',
            debitCredit: '',
            amount: 0,
            debitCredits: [{
                value: '',
                label: 'Select...',
            }, {
                value: 'debit',
                label: 'Debit',
            }, {
                value: 'credit',
                label: 'Credit',
            }],
            errDescription: '',
            errTransactionDate: '',
            errAmount: '',
            errDebitCredit: '',
        };

        this.save = this.save.bind(this);

    }

    componentDidMount() {
        this.loadStore();
    }

    loadStore() {
        StoresAPI.getStore(this.state.id, (data) => {
            this.setState({
                title: data.title,
            })
        })
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
            errDescription: '',
            errTransactionDate: '',
            errAmount: '',
            errDebitCredit: '',
        });
    }

    save() {

        var _ok = true;

        if (this.state.description === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errDescription: 'Please enter the description'
            });
        }

        if (_ok) {

            let payload = {
                storeId: this.state.id,
                transactionDate: this.state.transactionDate,
                description: this.state.description,
                amount: this.state.amount,
                debitCredit: this.state.debitCredit,
            }

            AccountsAPI.addTransaction(payload, (data) => {
                this.props.history.push(`/accounts/${this.state.id}`);
            });


        }

    }

    render() {

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <NarrowContainer>

                            <BackButton url={`/accounts/${this.state.id}`} title="Back" />
                            <Title title={"New Transaction"} />

                            <FormContainer title="Transaction Information"
                                description={`Please add the details for this transaction.`}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="transactionDate"
                                            type="date"
                                            label="Transaction Date"
                                            value={this.state.transactionDate}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errTransactionDate}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="title"
                                            type="text"
                                            label="Store"
                                            value={this.state.title}
                                            maxLength={150}
                                            disabled={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <SelectBox
                                            name="debitCredit"
                                            label="Debit/Credit"
                                            value={this.state.debitCredit}
                                            options={this.state.debitCredits}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errDebitCredit}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="amount"
                                            type="number"
                                            label="Transaction Amount"
                                            value={this.state.amount}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errAmount}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="description"
                                            type="text"
                                            label="Description"
                                            value={this.state.description}
                                            maxLength={250}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errDescription}
                                        />
                                    </div>
                                </div>


                            </FormContainer>

                            <Divider />

                            <div className="row">
                                <div className="col-sm-8" style={{ paddingBottom: 60 }}>
                                    &nbsp;
                                </div>
                                <div className="col-sm-4 text-right">
                                    <SubmitButton
                                        text="Add Transaction"
                                        className="btn"
                                        onClick={this.save}
                                    />
                                </div>
                            </div>

                        </NarrowContainer>
                    </div>
                </div>
            </div>
        )

    }

}

export default TransactionPage;