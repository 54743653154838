import React, { Component } from 'react';
import moment from 'moment';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { Title } from '../../components/Title';
import { Divider } from '../../components/Divider';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { TextInput, TextArea, CurrencyInput, InputWithButton } from '../../components/TextInput';
import { SelectBox, SelectBoxText } from '../../components/SelectBox';
import StoresAPI from '../../api/StoresAPI';
import ProductsAPI from '../../api/ProductsAPI';

class FilterPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            label: '',
            hasErrors: false,
            errTitle: '',
            options: [],
            option: '',
            errOption: '',
            collections: [],
            catLevel1: '',
            catLevel2: '',
            catLevel3: '',
            level1: [],
            level2: [],
            level3: [],
            collectionName: '',
        };
        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
        this.deleteOption = this.deleteOption.bind(this);
        this.addOption = this.addOption.bind(this);
        this.addCollection = this.addCollection.bind(this);
        this.deleteCollection = this.deleteCollection.bind(this);
    }

    componentDidMount() {
        if (this.state.id !== 'new') {
            this.loadFilter();
        }
        this.loadCollection('00000000-0000-0000-0000-000000000000', 1);
    }

    loadFilter() {
        ProductsAPI.getFilter(this.state.id, (data) => {
            this.setState({
                label: data.label,
                options: data.options,
                collections: data.collections,
            })
        })
    }

    loadCollection(id, level) {

        if (id == '00000000-0000-0000-0000-000000000000' && level > 1) {
            // Do Nothing
        } else {

            console.log("loadCollection('" + id + "', " + level + ")");
            ProductsAPI.getCollections(id, (data) => {
                var _arr = [];
                _arr.push({
                    value: '',
                    label: 'Select...',
                });

                if (level === 1) {
                    _arr.push({
                        value: '00000000-0000-0000-0000-000000000000',
                        label: 'All Collections'
                    });
                }

                for (var i = 0; i < data.length; i++) {
                    _arr.push({
                        value: data[i].id,
                        label: data[i].title,
                    });
                }
                if (level === 1) {

                    this.setState({
                        level1: _arr,
                        level2: [],
                        level3: [],
                    });
                }
                else if (level === 2) {
                    this.setState({
                        level2: _arr,
                        level3: []
                    });
                }
                else if (level === 3) {
                    this.setState({
                        level3: _arr,
                    });
                }
            })

        }

    }

    addOption() {
        this.setState({
            options: this.state.options.concat([{ value: this.state.option }]),
            option: '',
        });
    }


    deleteOption(_index) {
        this.setState({ options: this.state.options.filter((item, index) => index !== _index) });
    }

    addCollection() {
        var collectionId = '';
        if (this.state.catLevel3 != '') {
            collectionId = this.state.catLevel3;
        } else if (this.state.catLevel2 != '') {
            collectionId = this.state.catLevel2;
        } else {
            collectionId = this.state.catLevel1;
        }
        this.setState({
            collections: this.state.collections.concat([{ collectionId: collectionId, title: this.state.collectionName }]),
            catLevel3: '',
            catLevel2: '',
            catLevel1: '',
            level3: [],
            level2: [],
        });
    }

    deleteCollection(_index) {
        this.setState({ collections: this.state.collections.filter((item, index) => index !== _index) });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    onChangeSelect(name, value, text) {

        console.log("value: " + value + ", text: " + text);

        this.setState({
            [name]: value,
            collectionName: text,
        }, () => {
            if (name === 'catLevel1') {
                this.setState({
                    catLevel2: '',
                    catLevel3: '',
                });
                this.loadCollection(this.state.catLevel1, 2);
            } else if (name === 'catLevel2') {
                this.setState({
                    catLevel3: ''
                });
                this.loadCollection(this.state.catLevel2, 3);
            }
        });
    }

    save() {

        var _ok = true;

        if (this.state.label === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errTitle: 'Please enter the filter name'
            });
        }

        if (_ok) {

            let payload = {
                label: this.state.label,
                options: this.state.options,
                collections: this.state.collections,
            }

            if (this.state.id === 'new') {
                ProductsAPI.addFilter(payload, (data) => {
                    this.props.history.push(`/filters`);
                });
            } else {
                // Update product
                ProductsAPI.updFilter(this.state.id, payload, (data) => {
                    this.props.history.push(`/filters`);
                });
            }

        }

    }

    cancel() {
        this.props.history.push('/filters');
    }

    render() {

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <NarrowContainer>

                            <BackButton url="/filters" title="Back" />
                            <Title title={this.state.id === 'new' ? "New Filter" : "Edit Filter"} />

                            <FormContainer title="Filter Information"
                                description={`Please add the details for this filter.`}>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <TextInput
                                            name="label"
                                            type="text"
                                            label="Filter Name"
                                            value={this.state.label}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errLabel}
                                        />
                                    </div>
                                </div>

                            </FormContainer>

                            <Divider />

                            <FormContainer title="Filter Options"
                                description={`Available options for this filter.`}>
                                {this.state.options.length === 0 &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p>No filter options available.</p>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-sm-12">
                                        {this.state.options.map((item, index) =>
                                            <span className="grey-warning" style={{ fontSize: 13, float: 'left', marginRight: 8, whiteSpace: 'pre', marginBottom: 8 }} onClick={(e) => { this.deleteOption(index) }}>{item.value} <i style={{ marginTop: 5 }} className="far fa-times-circle"></i></span>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <InputWithButton
                                            name="option"
                                            type="text"
                                            label="Add Option"
                                            value={this.state.option}
                                            maxLength={45}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errOption}
                                            onClick={this.addOption}
                                            text="Add"
                                        />
                                    </div>
                                </div>
                            </FormContainer>

                            <Divider />

                            <FormContainer title="Filter Collections"
                                description={`Collections this filter applies to.`}>
                                {this.state.collections.length === 0 &&
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p>No collections added.</p>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-sm-12">
                                        {this.state.collections.map((item, index) =>
                                            <span className="grey-warning" style={{ fontSize: 13, float: 'left', marginRight: 8, whiteSpace: 'pre', marginBottom: 8 }} onClick={(e) => { this.deleteCollection(index) }}>{item.title} <i style={{ marginTop: 5 }} className="far fa-times-circle"></i></span>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <SelectBoxText
                                            name="catLevel1"
                                            label="Product Category"
                                            value={this.state.catLevel1}
                                            options={this.state.level1}
                                            onChange={this.onChangeSelect.bind(this)}
                                        />
                                    </div>
                                    {this.state.level2.length > 1 &&
                                        <div className="col-sm-3">
                                            <SelectBoxText
                                                name="catLevel2"
                                                label=" "
                                                value={this.state.catLevel2}
                                                options={this.state.level2}
                                                onChange={this.onChangeSelect.bind(this)}
                                            />
                                        </div>
                                    }
                                    {this.state.level3.length > 1 &&
                                        <div className="col-sm-3">
                                            <SelectBoxText
                                                name="catLevel3"
                                                label=" "
                                                value={this.state.catLevel3}
                                                options={this.state.level3}
                                                onChange={this.onChangeSelect.bind(this)}
                                            />
                                        </div>
                                    }
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label>&nbsp;</label>
                                            <SubmitButton
                                                text="Add Collection"
                                                className="btn"
                                                onClick={this.addCollection}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </FormContainer>

                            <Divider />

                            <div className="row">
                                <div className="col-sm-4">
                                    &nbsp;
                                </div>
                                <div className="col-sm-4" style={{ paddingBottom: 60 }}>
                                    &nbsp;
                                </div>
                                <div className="col-sm-4 text-right">
                                    <SubmitButton
                                        text="Cancel"
                                        className="btn btn-secondary"
                                        onClick={this.cancel}
                                    />
                                    <SubmitButton
                                        text="Save Filter"
                                        className="btn"
                                        onClick={this.save}
                                    />
                                </div>
                            </div>

                        </NarrowContainer>
                    </div>
                </div>
            </div>
        )

    }

}

export default FilterPage;