import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Route, Redirect, Link} from 'react-router-dom';
import { SubmitButton } from '../../components/Button';
import { TextInput } from '../../components/TextInput';
import { Container } from '../../components/Container';
import UsersAPI from '../../api/UsersAPI';

class PasswordPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            loginPassword: '',
            loginPassword2: '',
            submitted: false,
        };

        this.handleReset = this.handleReset.bind(this);
        this.goBack = this.goBack.bind(this);

    }

    goBack() {
        this.props.history.push(`/login`);
    }
    
    handleReset() {

        this.setState({
            isLoading: true
        });

        let payload = {
            resetToken: this.state.id,
            password: this.state.loginPassword,
        };

        UsersAPI.setPassword(payload, (data) => {
            this.setState({
                submitted: true
            });
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
            error: '',
        });
    }

    render() {

        return (

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-sm-12 login">

                        <p><img src="/assets/img/CashGenerator.png" /></p>
                        
                        {!this.state.submitted &&
                        <Container>

                            <div className="row">
                                <div className="col-sm-12">
                                    <TextInput 
                                        name="loginPassword"
                                        type="password"
                                        label="Choose Password"
                                        placeholder="Enter password..."
                                        value={this.state.loginPassword}
                                        maxLength={50}
                                        asterix={true}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <TextInput 
                                        name="loginPassword2"
                                        type="password"
                                        label="Re-Enter Password"
                                        placeholder="Enter password..."
                                        value={this.state.loginPassword2}
                                        maxLength={50}
                                        asterix={true}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                            </div>
                            {this.state.error &&
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <p className="error">{this.state.error}</p>
                                </div>
                            </div>
                            }
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <SubmitButton
                                        text={'Set Password'}
                                        className="btn"
                                        onClick={this.handleReset}
                                    />
                                </div>
                            </div>
                            
                        </Container>
                        }

                        {this.state.submitted &&
                        <Container>

                            <div className="row">
                                <div className="col-sm-12">
                                    <p>Your password has been reset. Click below to sign in.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <SubmitButton
                                        text={'Sign In'}
                                        className="btn"
                                        onClick={this.goBack}
                                    />
                                </div>
                            </div>

                        </Container>
                    }

                    </div>
                </div>
            </div>

        )
    }

}

PasswordPage.propTypes = {
    isLoggedIn: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.account.isLoggedIn,
    }
};

export default connect(mapStateToProps)(PasswordPage);