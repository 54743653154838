import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Title } from '../../components/Title';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { TextInput, InputWithButton } from '../../components/TextInput';
import StoresAPI from '../../api/StoresAPI';
import UsersAPI from '../../api/UsersAPI';

class StoresPage extends Component {

    constructor() {
        super();
        this.state = {
            keyword: '',
            stores: [],
            userLevel: 'user',
            userId: ''
        }

    }

    componentDidMount() {
        UsersAPI.getUserData((data) => {
            this.setState({
                userLevel: data.userLevel,
                userId: data.id
            }, () => {
                if (data.userLevel === 'admin') {
                    this.loadStores();
                } else {
                    this.loadUserStores();
                }
            });
        });
    }

    loadUserStores() {
        UsersAPI.getUserStores(this.state.userId, (data) => {
            this.setState({
                stores: data
            });
        });
    }

    loadStores() {
        StoresAPI.getStores((data) => {
            this.setState({
                stores: data
            });
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    render() {

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <Title title="Stores" button="Add new" link="/stores/new" />

                        <Container>

                            <div className="row">
                                <div className="col-sm-12">
                                    <TextInput
                                        name="keyword"
                                        placeholder="Search stores"
                                        text="Search"
                                        value={this.state.keyword}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Store Name</th>
                                                <th>Address</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.stores.filter(d => this.state.keyword === '' || (d.title.toLowerCase() + d.line1.toLowerCase() + d.line2.toLowerCase() + d.town.toLowerCase() + d.postCode.toLowerCase()).includes(this.state.keyword.toLowerCase())).map((item, index) =>
                                                <tr key={index}>
                                                    <td><Link to={`/stores/${item.id}`}>{item.title}</Link></td>
                                                    <td>{item.line1}{item.line2 !== '' && `, ${item.line2}`}, {item.town}, {item.postCode}</td>
                                                    <td>
                                                        {String(item.proPlan) != '' && String(item.proPlan) =='true' ?  <a target="_blank" href={`https://cashgenerator.co.uk/pages/${item.title.toLowerCase().replace(" ", "-")}`}><i className="fas fa-external-link-alt"></i></a> :  <a target="_blank" href={`https://cashgenerator.co.uk/collections/vendors?q=Cash%20Generator%20${item.title.replace(" ", "%20")}`}><i className="fas fa-external-link-alt"></i></a>}
                                                    </td>
                                                </tr>
                                            )}
                                       
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </Container>

                    </div>
                </div>
            </div>
        )

    }

}

StoresPage.propTypes = {
    firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        firstName: state.account.firstName,
    }
};

export default connect(mapStateToProps)(StoresPage);