import React, { Component, useState } from 'react';
import moment from 'moment';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { Title } from '../../components/Title';
import { Divider } from '../../components/Divider';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { TextInput, TextArea, CurrencyInput } from '../../components/TextInput';
import { SelectBox } from '../../components/SelectBox';
import StoresAPI from '../../api/StoresAPI';
import LookupAPI from '../../api/LookupAPI';
import UsersAPI from '../../api/UsersAPI';
import { TextField } from '@material-ui/core';




class StorePage extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            accounting: false,
            title: '',
            line1: '',
            line2: '',
            town: '',
            postCode: '',
            telephone: '',
            whatsApp:'',
            email: '',
            contactName: '',
            hasErrors: false,
            errTitle: '',
            longitude: 0,
            latitude: 0,
            cheque: false,
            gold: false,
            moneyTransfer: false,
            travel: false,
            vaping: false,
            openHours: "",
            userLevel: 'user',
            userId: '',
            proPlan: false,
            liveChat: false,
            accountName: '',
            accountNumber: '',
            sortCode: '',
            errAccountName: '',
            errAccountNumber: '',
            errSortCode: '',
            accountsEmail: '',
            monFrmtime:'00:00',
            monTotime:'00:00',
            tueFrmtime:'00:00',
            tueTotime:'00:00',
            wedFrmtime:'00:00',
            wedTotime:'00:00',
            thuFrmtime:'00:00',
            thuTotime:'00:00',
            friFrmtime:'00:00',
            friTotime:'00:00',
            satFrmtime:'00:00',
            satTotime:'00:00',
            sunFrmtime:'00:00',
            sunTotime:'00:00',
        };
        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.geocode = this.geocode.bind(this);
    }

    componentDidMount() {
        UsersAPI.getUserData((data) => {
            this.setState({
                userLevel: data.userLevel,
                userId: data.id,
                accounting: data.accounting,
            }, () => {
                if (this.state.id !== 'new') {
                    this.loadStore();
                }
            });
        });
    }

    loadStore() {
        StoresAPI.getStore(this.state.id, (data) => {
            this.setState({
                title: data.title,
                line1: data.line1,
                line2: data.line2,
                town: data.town,
                postCode: data.postCode,
                telephone: data.telephone,
                whatsApp: data.whatsApp,
                email: data.email,
                contactName: data.contactName,
                longitude: data.longitude,
                latitude: data.latitude,
                cheque: data.cheque,
                gold: data.gold,
                moneyTransfer: data.moneyTransfer,
                travel: data.travel,
                vaping: data.vaping,
                openHours: data.openHours,
                proPlan: data.proPlan,
                liveChat: data.liveChat,
                groupName: data.groupName,
                accountName: data.accountName,
                accountNumber: data.accountNumber,
                sortCode: data.sortCode,
                accountsEmail: data.accountsEmail,
                monFrmtime:data.monFrmtime,
                monTotime:data.monTotime,
                tueFrmtime:data.tueFrmtime,
                tueTotime:data.tueTotime,
                wedFrmtime:data.wedFrmtime,
                wedTotime:data.wedTotime,
                thuFrmtime:data.thuFrmtime,
                thuTotime:data.thuTotime,
                friFrmtime:data.friFrmtime,
                friTotime:data.friTotime,
                satFrmtime:data.satFrmtime,
                satTotime:data.satTotime,
                sunFrmtime:data.sunFrmtime,
                sunTotime:data.sunTotime,
            })
        })
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
            errAccountName: '',
            errAccountNumber: '',
            errSortCode: '',
            errTitle: ''
        });
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
        
    }
    
    save() {

        var _ok = true;

        if (this.state.title === '') {
            _ok = false;
            this.setState({
                hasErrors: true,
                errTitle: 'Please enter the store name'
            });
        }

        if (_ok) {

            let payload = {
                title: this.state.title,
                line1: this.state.line1,
                line2: this.state.line2,
                town: this.state.town,
                postCode: this.state.postCode,
                telephone: this.state.telephone,
                whatsApp: this.state.whatsApp,
                email: this.state.email,
                contactName: this.state.contactName,
                longitude: this.state.longitude,
                latitude: this.state.latitude,
                cheque: this.state.cheque,
                gold: this.state.gold,
                moneyTransfer: this.state.moneyTransfer,
                travel: this.state.travel,
                vaping: this.state.vaping,
                openHours: this.state.openHours,
                proPlan: this.state.proPlan,
                liveChat: this.state.liveChat,
                groupName: this.state.groupName,
                accountName: this.state.accountName,
                accountNumber: this.state.accountNumber,
                sortCode: this.state.sortCode,
                accountsEmail: this.state.accountsEmail,
                monFrmtime:this.state.monFrmtime,
                monTotime:this.state.monTotime,
                tueFrmtime:this.state.tueFrmtime,
                tueTotime:this.state.tueTotime,
                wedFrmtime:this.state.wedFrmtime,
                wedTotime:this.state.wedTotime,
                thuFrmtime:this.state.thuFrmtime,
                thuTotime:this.state.thuTotime,
                friFrmtime:this.state.friFrmtime,
                friTotime:this.state.friTotime,
                satFrmtime:this.state.satFrmtime,
                satTotime:this.state.satTotime,
                sunFrmtime:this.state.sunFrmtime,
                sunTotime:this.state.sunTotime,

            }
            
            if (this.state.id === 'new') {
                StoresAPI.addStore(payload, (data) => {
                    this.props.history.push(`/stores`);
                });
            } else {
                // Update product
                StoresAPI.updStore(this.state.id, payload, (data) => {
                    this.props.history.push(`/stores`);
                });
            }

        }

    }

    geocode() {

        let _location = this.state.line1 + " " + this.state.town + " " + this.state.postCode;

        LookupAPI.getGeoLocation(_location, (data) => {
            if (data) {
                this.setState({
                    longitude: data.lng,
                    latitude: data.lat
                });
            }
        })
    }

    delete() {
        StoresAPI.deleteStore(this.state.id, (data) => {
            this.props.history.push('/stores');
        })
    }

    cancel() {
        this.props.history.push('/stores');
    }

    render() {
         return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <NarrowContainer>

                            <BackButton url="/stores" title="Back" />
                            <Title title={this.state.id === 'new' ? "New Store" : "Edit Store"} />

                            <FormContainer title="Store Information"
                                description={`Please add the details for this store.`}>
                                {this.state.userLevel === 'admin' &&
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <TextInput
                                                name="groupName"
                                                type="text"
                                                label="Group Name"
                                                value={this.state.groupName}
                                                maxLength={150}
                                                onChange={this.onChange.bind(this)}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="title"
                                            type="text"
                                            label="Store Name"
                                            value={this.state.title}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                            error={this.state.errTitle}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="contactName"
                                            type="text"
                                            label="Contact Name"
                                            value={this.state.contactName}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="line1"
                                            type="text"
                                            label="Address Line 1"
                                            value={this.state.line1}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="line2"
                                            type="text"
                                            label="Address Line 2"
                                            value={this.state.line2}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="town"
                                            type="text"
                                            label="Town"
                                            value={this.state.town}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="postCode"
                                            type="text"
                                            label="Post Code"
                                            value={this.state.postCode}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                               
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="email"
                                            type="text"
                                            label="Email Address"
                                            value={this.state.email}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="telephone"
                                            type="text"
                                            label="Telephone"
                                            value={this.state.telephone}
                                            maxLength={150}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="whatsApp"
                                            type="text"
                                            label="WhatsApp"
                                            value={this.state.whatsApp}
                                            maxLength={20}
                                            asterix={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <TextInput
                                            name="longitude"
                                            type="text"
                                            label="Longitude"
                                            value={this.state.longitude}
                                            maxLength={150}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <TextInput
                                            name="latitude"
                                            type="text"
                                            label="Latitude"
                                            value={this.state.latitude}
                                            maxLength={150}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label>&nbsp;</label>
                                            <div>
                                                <SubmitButton
                                                    text="Lookup"
                                                    className="btn btn-full btn-secondary"
                                                    onClick={this.geocode}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-3">
                                        <SwitchButton
                                            label="Cheque Cashing"
                                            name="cheque"
                                            value={this.state.cheque}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <SwitchButton
                                            label="Sell Your Gold"
                                            name="gold"
                                            value={this.state.gold}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <SwitchButton
                                            label="Travel Money"
                                            name="travel"
                                            value={this.state.travel}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <SwitchButton
                                            label="Vaping"
                                            name="vaping"
                                            value={this.state.vaping}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <SwitchButton
                                            label="Transfer Money Abroad"
                                            name="moneyTransfer"
                                            value={this.state.moneyTransfer}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                
                               <div className="row">
                                    <div className="col-sm-12">
                                       <label> Open Hours </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                       <table>
                                        <tr>
                                            <th>Weekday</th>
                                            <th>From</th>
                                            <th>To</th>
                                        </tr>
                                        <tr>
                                            <td>Monday</td>
                                            <td><TextField
                                                name="monFrmtime"
                                                type="time"
                                                value={this.state.monFrmtime}
                                                onChange={this.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                /></td>
                                                 <td><TextField
                                                name="monTotime"
                                                type="time"
                                                value={this.state.monTotime}
                                                onChange={this.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                /></td>
                                        </tr>
                                        <tr>
                                            <td>Tuesday</td>
                                            <td><TextField
                                                name="tueFrmtime"
                                                type="time"
                                                value={this.state.tueFrmtime}
                                                onChange={this.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                /></td>
                                                 <td><TextField
                                                name="tueTotime"
                                                type="time"
                                                value={this.state.tueTotime}
                                                onChange={this.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                /></td>
                                        </tr>
                                        <tr>
                                            <td>Wednesday</td>
                                            <td><TextField
                                                name="wedFrmtime"
                                                type="time"
                                                value={this.state.wedFrmtime}
                                                onChange={this.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                /></td>
                                                 <td><TextField
                                                name="wedTotime"
                                                type="time"
                                                value={this.state.wedTotime}
                                                onChange={this.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                /></td>
                                        </tr>
                                        <tr>
                                            <td>Thursday</td>
                                            <td><TextField
                                                name="thuFrmtime"
                                                type="time"
                                                value={this.state.thuFrmtime}
                                                onChange={this.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                /></td>
                                                 <td><TextField
                                                name="thuTotime"
                                                type="time"
                                                value={this.state.thuTotime}
                                                onChange={this.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                /></td>
                                        </tr>
                                        <tr>
                                            <td>Friday</td>
                                            <td><TextField
                                                name="friFrmtime"
                                                type="time"
                                                value={this.state.friFrmtime}
                                                onChange={this.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                /></td>
                                                 <td><TextField
                                                name="friTotime"
                                                type="time"
                                                value={this.state.friTotime}
                                                onChange={this.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                /></td>
                                        </tr>
                                        <tr>
                                            <td>Saturday</td>
                                            <td><TextField
                                                name="satFrmtime"
                                                type="time"
                                                value={this.state.satFrmtime}
                                                onChange={this.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                /></td>
                                                 <td><TextField
                                                name="satTotime"
                                                type="time"
                                                value={this.state.satTotime}
                                                onChange={this.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                /></td>
                                        </tr>
                                        <tr>
                                            <td>Sunday</td>
                                            <td><TextField
                                                name="sunFrmtime"
                                                type="time"
                                                value={this.state.sunFrmtime}
                                                onChange={this.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                /></td>
                                                 <td><TextField
                                                name="sunTotime"
                                                type="time"
                                                value={this.state.sunTotime}
                                                onChange={this.handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                /></td>
                                        </tr>
                                        
                                        </table>
                                    </div>
                                </div>
                               
                            </FormContainer>

                            <Divider />

                            {(this.state.userLevel === 'admin' && this.state.accounting) &&
                                <FormContainer title="Payment Information"
                                    description={`Please add the payment information for this store.`}>

                                    <div className="row">
                                        <div className="col-sm-4">
                                            <TextInput
                                                name="accountName"
                                                type="text"
                                                label="Account Name"
                                                value={this.state.accountName}
                                                maxLength={150}
                                                onChange={this.onChange.bind(this)}
                                                error={this.state.errAccountName}
                                            />
                                        </div>
                                        <div className="col-sm-4">
                                            <TextInput
                                                name="accountNumber"
                                                type="text"
                                                label="Account Number"
                                                value={this.state.accountNumber}
                                                maxLength={150}
                                                onChange={this.onChange.bind(this)}
                                                error={this.state.errAccountNumber}
                                            />
                                        </div>
                                        <div className="col-sm-4">
                                            <TextInput
                                                name="sortCode"
                                                type="text"
                                                label="Sort Code"
                                                value={this.state.sortCode}
                                                maxLength={150}
                                                onChange={this.onChange.bind(this)}
                                                error={this.state.errSortCode}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <TextInput
                                                name="accountsEmail"
                                                type="text"
                                                label="Accounts Email Recipients"
                                                value={this.state.accountsEmail}
                                                maxLength={500}
                                                onChange={this.onChange.bind(this)}
                                                notes="(Separate multiple email addresses by a comma)"
                                            />
                                        </div>
                                    </div>

                                </FormContainer>
                            }

                            {this.state.userLevel === 'admin' &&
                                <Divider />
                            }

                            {this.state.userLevel === 'admin' &&
                                <FormContainer title="Store Settings"
                                    description={`Configuration settings for this store.`}>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <SwitchButton
                                                label="Pro Plan (P2) Store"
                                                name="proPlan"
                                                value={this.state.proPlan}
                                                onChange={this.onChange.bind(this)}
                                            />
                                        </div>
                                        <div className="col-sm-4">
                                            <SwitchButton
                                                label="Live Chat"
                                                name="liveChat"
                                                value={this.state.liveChat}
                                                onChange={this.onChange.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </FormContainer>
                            }

                            {this.state.userLevel === 'admin' &&
                                <Divider />
                            }

                            <div className="row">
                                <div className="col-sm-4">
                                    {(this.state.id !== 'new' && this.state.userLevel === 'admin') &&
                                        <SubmitButton
                                            text="Delete Store"
                                            className="btn btn-secondary"
                                            onClick={(e) => { if (window.confirm('Are you sure you wish to delete this store?')) this.delete() }}
                                        />
                                    }
                                </div>
                                <div className="col-sm-4" style={{ paddingBottom: 60 }}>
                                    &nbsp;
                                </div>
                                <div className="col-sm-4 text-right">
                                    <SubmitButton
                                        text="Cancel"
                                        className="btn btn-secondary"
                                        onClick={this.cancel}
                                    />
                                    <SubmitButton
                                        text="Save Store"
                                        className="btn"
                                        onClick={this.save}
                                    />
                                </div>
                            </div>

                        </NarrowContainer>
                    </div>
                </div>
            </div>
        )

    }

}

export default StorePage;