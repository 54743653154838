import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SharedFunctions from '../../util/SharedFunctions';
import moment from 'moment';
import { Title } from '../../components/Title';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { InputWithButton } from '../../components/TextInput';
import ChannelAPI from '../../api/ChannelAPI';
import UsersAPI from '../../api/UsersAPI';

class HomePage extends Component {

    constructor() {
        super();
        this.state = {
            firstname: '',
            lastname: ''
        }

    }

    componentDidMount() {
        UsersAPI.getUserData((data) => {
            let _name = "";
            if (data.firstName !== '' && data.firstName !== null) {
                _name = data.firstName;
            } else {
                _name = data.lastName;
            }
            this.setState({
                name: _name
            });;
        })
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    render() {

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <Title title={`${SharedFunctions.getGreetingTime(moment())}, ${this.state.name}`} />

                        <Container>

                            <h4>Web EPOS Dashboard</h4>



                        </Container>

                    </div>
                </div>
            </div>
        )

    }

}

HomePage.propTypes = {
    firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        firstName: state.account.firstName,
    }
};

export default connect(mapStateToProps)(HomePage);