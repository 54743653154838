import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Title } from '../../components/Title';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { TextInput, InputWithButton } from '../../components/TextInput';
import OrdersAPI from '../../api/OrdersAPI';
import { SelectBox } from '../../components/SelectBox';
import { Loader } from '../../components/Loader';
import UsersAPI from '../../api/UsersAPI';
import StoresAPI from '../../api/StoresAPI';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import ChatsAPI from '../../api/ChatsAPI';
import AccountsAPI from '../../api/AccountsAPI';

class AccountPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            isLoading: true,
            page: 1,
            size: 30,
            count: 0,
            transactions: [],
            title: '',
            start: '2021-01-01',
            end: moment().format("YYYY-MM-DD"),
        }

        this.loadAccount = this.loadAccount.bind(this);
        this.deleteTransaction = this.deleteTransaction.bind(this);

    }

    loadStore() {
        StoresAPI.getStore(this.state.id, (data) => {
            this.setState({
                title: data.title,
            })
        })
    }

    componentDidMount() {
        this.checkUserIsAdmin();
        UsersAPI.getUserData((data) => {
            console.log(data);
            this.setState({
                userLevel: data.userLevel,
                userId: data.id,
            });
        })
        this.loadStore();
        this.loadAccount();
    }

    loadAccount() {
        this.setState({
            isLoading: true
        }, () => {
            AccountsAPI.getAccount(this.state.id, this.state.start, this.state.end, (data) => {
                this.setState({
                    isLoading: false,
                    transactions: data
                });
            });
        });
    }

    deleteTransaction(id) {
        AccountsAPI.delTransaction(id, (data) => {
            this.loadAccount();
        })
    }

    checkUserIsAdmin() {
        UsersAPI.getUserData((data) => {
            UsersAPI.getUser(data.id, (_usr) => {
                if (_usr.userLevel !== 'admin') {
                    this.props.history.push(`/`);
                }
            });
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    render() {

        const sum = this.state.transactions.map(v => v.amount).reduce((sum, current) => parseFloat(sum) + parseFloat(current), 0);

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">

                        <BackButton url={'/accounts'} title="Back" />
                        <Title title={`${this.state.title} Accounts`} button="Add new" link={`/transaction/${this.state.id}`} />

                        <Container>

                            <div className="row">
                                <div className="col-sm-2">
                                    <TextInput
                                        name="start"
                                        type="date"
                                        label="Period From"
                                        value={this.state.start}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <TextInput
                                        name="end"
                                        type="date"
                                        label="Period To"
                                        value={this.state.end}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <label htmlFor={this.props.name}>&nbsp;</label>
                                        <div className="input-group">
                                            <SubmitButton
                                                text="Filter"
                                                className="btn btn-full"
                                                onClick={this.loadAccount}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <div className="row">
                                <div className="col-sm-12">

                                    {this.state.isLoading ?
                                        <Loader />
                                        :
                                        <div>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th width="10%">Date/Time</th>
                                                        <th width="60%">Description</th>
                                                        <th width="10%">Debit/Credit</th>
                                                        <th width="10%">Amount</th>
                                                        <th width="10%">Balance</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.transactions.map((item, index) =>
                                                        <tr key={index} className={item.isPayout ? `payout-row` : ''}>
                                                            <td style={{ fontSize: 12 }}>{moment(item.transactionDate).format("ddd DD MMM YY @ HH:mm")}</td>
                                                            {item.isManual ?
                                                                <td style={{ whiteSpace: 'pre-wrap', fontSize: 12, lineHeight: '15px', paddingTop: '5px', paddingBottom: '5px' }}><a target="_blank" href={item.url}>{item.description}</a><span style={{ float: 'right', cursor: 'pointer' }} onClick={() => { if (window.confirm(`Are you sure you wish to delete this transaction?`)) this.deleteTransaction(item.transactionId) }}><i style={{ marginTop: 5 }} className="far fa-times-circle"></i></span></td>
                                                                :
                                                                <td style={{ whiteSpace: 'pre-wrap', fontSize: 12, lineHeight: '15px', paddingTop: '5px', paddingBottom: '5px' }}><Link to={`/orders/${item.orderId}`}>{item.description}</Link></td>
                                                            }
                                                            <td style={{ fontSize: 12 }}>{item.debitCredit}</td>
                                                            <td style={{ fontSize: 12 }}>&pound;{item.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                            <td style={{ fontSize: 12 }}>&pound;{item.balance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                        </tr>
                                                    )}
                                                    {sum > 0 &&
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td>&nbsp;</td>
                                                            <td><strong>&pound;{sum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                                            <td><strong>&pound;{this.state.transactions[this.state.transactions.length - 1].balance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                                        </tr>
                                                    }
                                                    {this.state.transactions.length === 0 &&
                                                        <tr>
                                                            <td colSpan="5" className="text-center">There are no transactions.</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    }

                                </div>
                            </div>

                        </Container>

                    </div>
                </div>
            </div>
        )

    }

}

AccountPage.propTypes = {
    firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        firstName: state.account.firstName,
    }
};

export default connect(mapStateToProps)(AccountPage);