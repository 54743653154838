import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class TextInput extends React.Component {

    constructor(props) {
        super(props);
        if (this.props.url && this.props.button) {
            console.warn('Both a url and button prop were passed to the TextInput component');
        }
    }

    onFieldChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.props.onChange(name, value);
    }

    validateInput(e) {
        if (this.props.pattern) {
            const re = RegExp(this.props.pattern);
            if (!re.test(e.key)) {
                e.preventDefault();
            }
        }

        if (this.props.alhpaNumeric) {
            const re = RegExp(/[0-9A-Za-z]+/g);
            if (!re.test(e.key)) {
                e.preventDefault();
            }
        }
    }

    render() {
        return (
            <div className="form-group">
                {this.props.label ? <label htmlFor={this.props.name}>{this.props.label}{this.props.asterix ? <span className="asterix">*</span> : ''}</label> : ''}
                {this.props.tip ? <div className="tip">{this.props.tip}</div> : ''}
                <input
                    id={this.props.name}
                    type={this.props.type ? this.props.type : "text"}
                    name={`${this.props.name}`}
                    placeholder={this.props.placeholder}
                    onChange={this.onFieldChange.bind(this)}
                    maxLength={this.props.maxLength ? this.props.maxLength : 200}
                    value={this.props.toUpper && this.props.value ? this.props.value.toUpperCase() : this.props.value}
                    className={this.props.class ? `${this.props.class} form-control` : 'form-control'}
                    onKeyPress={(e) => this.validateInput(e)}
                    autoComplete={`auto-${this.props.name}`}
                    pattern={this.props.pattern ? this.props.pattern : null}
                    disabled={this.props.disabled ? this.props.disabled : false}
                    step={this.props.step ? this.props.step : 0.01}
                    min={0}
                />
                <div className="input-meta">
                    {this.props.notes ? <div><small><em>{this.props.notes}</em></small></div> : ''}
                    {this.props.error ? <div className="invalid-error input-meta--error">{this.props.error}</div> : ''}
                </div>
            </div>
        )
    }

};

TextInput.propTypes = {
    id: PropTypes.string.isRequired,
    alhpaNumeric: PropTypes.bool,
    button: PropTypes.func,
    url: PropTypes.string,
    error: PropTypes.string,
    link: PropTypes.string,
    pattern: PropTypes.instanceOf(RegExp),
    class: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    maxLength: PropTypes.number,
    toUpper: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default TextInput;