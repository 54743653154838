import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Route, Redirect, Link} from 'react-router-dom';
import { SubmitButton } from '../../components/Button';
import { TextInput } from '../../components/TextInput';
import { Container } from '../../components/Container';
import UsersAPI from '../../api/UsersAPI';

class ResetPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            loginEmail: '',
            submitted: false,
        };

        this.resetPassword = this.resetPassword.bind(this);
        this.goBack = this.goBack.bind(this);

    }

    goBack() {
        this.props.history.push(`/login`);
    }
    
    resetPassword() {

        this.setState({
            isLoading: true
        });

        let payload = {
            email: this.state.loginEmail,
        };

        UsersAPI.resetPassword(payload, (data) => {
            this.setState({
                submitted: true
            });
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
            error: '',
        });
    }

    render() {

        return (

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-sm-12 login">

                        <p><img src="/assets/img/CashGenerator.png" /></p>
                        
                        {!this.state.submitted &&
                        <Container>

                            <div className="row">
                                <div className="col-sm-12">
                                    <TextInput 
                                        name="loginEmail"
                                        type="email"
                                        label="Email Address"
                                        placeholder="Enter email address..."
                                        value={this.state.loginEmail}
                                        maxLength={45}
                                        asterix={true}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                            </div>
                            {this.state.error &&
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <p className="error">{this.state.error}</p>
                                </div>
                            </div>
                            }
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <SubmitButton
                                        text={'Back'}
                                        className="btn btn-secondary"
                                        onClick={this.goBack}
                                    />
                                    <SubmitButton
                                        text={'Reset Password'}
                                        disabled={this.state.isLoading}
                                        className="btn"
                                        onClick={this.resetPassword}
                                    />
                                </div>
                            </div>
                            
                        </Container>
                        }

                        {this.state.submitted &&
                        <Container>

                         <div className="row">
                             <div className="col-sm-12">
                                <p>Please check your email to reset your password.</p>
                             </div>
                         </div>

                     </Container>
                        }
                        

                    </div>
                </div>
            </div>

        )
    }

}

ResetPage.propTypes = {
    isLoggedIn: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.account.isLoggedIn,
    }
};

export default connect(mapStateToProps)(ResetPage);