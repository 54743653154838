import React, { Component } from 'react';
import moment from 'moment';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { Title } from '../../components/Title';
import { Divider } from '../../components/Divider';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { TextInput, TextArea, CurrencyInput } from '../../components/TextInput';
import { SelectBox } from '../../components/SelectBox';
import StoresAPI from '../../api/StoresAPI';
import OrdersAPI from '../../api/OrdersAPI';
import { Notes } from '../../components/Notes';
import ChatsAPI from '../../api/ChatsAPI';

class ChatPage extends Component {

    intervalID;

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            fromName: '',
            fromEmail: '',
            fromTelephone: '',
            storeName: '',
            chat: [],
            message: '',
            errMessage: '',
            chatType: '',
        };

        this.addReply = this.addReply.bind(this);

    }

    componentDidMount() {
        this.loadChat();
        this.intervalID = setInterval(this.loadChat.bind(this), 5000); // 60 secs
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    addReply() {
        let payload = {
            parentId: this.state.id,
            message: this.state.message,
            isReply: true,
        };

        ChatsAPI.addChat(payload, (data) => {
            this.setState({
                message: '',
            }, () => {
                this.loadChat();
            })
        })
    }

    loadChat() {
        ChatsAPI.getchat(this.state.id, (data) => {
            console.log(data);
            this.setState({
                fromName: data[0].fromName,
                fromEmail: data[0].fromEmail,
                fromTelephone: data[0].fromTelephone,
                storeName: data[0].storeName,
                chatType: data[0].chatType,
                chat: data
            })
        })
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    render() {

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <NarrowContainer>

                            <BackButton url={'/chats'} title="Back" />
                            <Title title={`Chat`} />

                            <FormContainer title="Customer Information"
                                description={``}>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="fromName"
                                            type="text"
                                            label="Customer Name"
                                            value={this.state.fromName}
                                            maxLength={150}
                                            disabled={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="storeName"
                                            type="text"
                                            label="Store"
                                            value={this.state.storeName}
                                            maxLength={150}
                                            disabled={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="fromEmail"
                                            type="text"
                                            label="Email Address"
                                            value={this.state.fromEmail}
                                            maxLength={150}
                                            disabled={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="fromTelephone"
                                            type="text"
                                            label="Telephone"
                                            value={this.state.fromTelephone}
                                            maxLength={150}
                                            disabled={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>

                            </FormContainer>

                            <Divider />

                            <FormContainer title="Message Thread"
                                description={``}>

                                {this.state.chat.map((item, index) =>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {item.isReply ?
                                                <div className="chat-right">
                                                    <div className="message">{item.message}</div>
                                                    <div className="time">{moment(item.messageDate).format("DD MMM @ HH:mm")}</div>
                                                </div>
                                                :
                                                <div className="chat-left">
                                                    <div className="message">{item.message}</div>
                                                    <div className="time">{moment(item.messageDate).format("DD MMM @ HH:mm")}</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )}

                                <hr />

                                {this.state.chatType === 'chat' ?
                                    <div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <TextArea
                                                    name="message"
                                                    type="text"
                                                    value={this.state.message}
                                                    onChange={this.onChange.bind(this)}
                                                    error={this.state.errMessage}
                                                    rows={3}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <SubmitButton
                                                    text="Send Reply"
                                                    className="btn"
                                                    onClick={this.addReply}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p className="chat-direct">This enquiry was received via a contact form. To respond to the customer, please contact them directly using their contact details above.</p>
                                        </div>
                                    </div>
                                }

                            </FormContainer>

                        </NarrowContainer>
                    </div>
                </div>
            </div>
        )

    }

}

export default ChatPage;