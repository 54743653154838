import apiRequest from '.';
import APIUtils from './APIUtils';

class StoresAPI {

    getNotes(id, cb) {
        apiRequest({ endpoint: `notes/${id}` }, cb);
    }

    addNote(payload, cb) {
        apiRequest({ endpoint: `notes`, method: 'POST', payload }, cb);
    }

}

export default new StoresAPI();