import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Title } from '../../components/Title';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { TextInput, InputWithButton } from '../../components/TextInput';
import UsersAPI from '../../api/UsersAPI';

class UsersPage extends Component {

    constructor() {
        super();
        this.state = {
            keyword: '',
            users: [],
        }

    }

    componentDidMount() {
        this.checkUserIsAdmin();
        this.loadUsers();
    }

    checkUserIsAdmin() {
        UsersAPI.getUserData((data) => {
            UsersAPI.getUser(data.id, (_usr) => {
                if (_usr.userLevel !== 'admin') {
                    this.props.history.push(`/`);
                }
            });
        });
    }

    loadUsers() {
        UsersAPI.getUsers((data) => {
            console.log("Users", data);
            this.setState({
                users: data
            });
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    render() {

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <Title title="Users" button="Add new" link="/users/new" />

                        <Container>

                            <div className="row">
                                <div className="col-sm-12">
                                    <TextInput
                                        name="keyword"
                                        placeholder="Search users"
                                        text="Search"
                                        value={this.state.keyword}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>User Name</th>
                                                <th>Company Name</th>
                                                <th>Email</th>
                                                <th>User Level</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.users.filter(d => this.state.keyword === '' || (d.firstName.toLowerCase() + d.lastName.toLowerCase() + d.companyName.toLowerCase() + d.email.toLowerCase()).includes(this.state.keyword.toLowerCase())).map((item, index) =>
                                                <tr key={index}>
                                                    <td><Link to={`/users/${item.id}`}>{(item.firstName === '' && item.lastName === '') ? 'Unknown' : `${item.firstName} ${item.lastName}`}</Link></td>
                                                    <td>{item.companyName}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.userLevel == 'admin' ? <span className="yellow-warning">Admin</span> : <span className="grey-warning">User</span>}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </Container>

                    </div>
                </div>
            </div>
        )

    }

}

UsersPage.propTypes = {
    firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        firstName: state.account.firstName,
    }
};

export default connect(mapStateToProps)(UsersPage);