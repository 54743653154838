import apiRequest from '.';
import APIUtils from './APIUtils';

class UsersAPI {

    isLoggedIn(cb) {
        if (localStorage.hasOwnProperty('userData')) {
            cb(true);
        } else {
            cb(false);
        }
    }

    getUserData(cb) {
        if (localStorage.hasOwnProperty('userData')) {
            cb(JSON.parse(localStorage.getItem('userData')));
        } else {
            cb(null);
        }
    }

    auth(payload, cb) {
        apiRequest({ endpoint: `users/auth`, method: 'POST', payload }, cb);
    }

    getUsers(cb) {
        apiRequest({ endpoint: 'users' }, cb);
    }

    getUser(id, cb) {
        apiRequest({ endpoint: `users/${id}` }, cb);
    }

    addUser(payload, cb) {
        apiRequest({ endpoint: `users`, method: 'POST', payload }, cb);
    }

    updUser(id, payload, cb) {
        apiRequest({ endpoint: `users/${id}`, method: 'PUT', payload }, cb);
    }

    setPassword(id, payload, cb) {
        apiRequest({ endpoint: `users/password/${id}`, method: 'PUT', payload }, cb);
    }

    delUser(id, cb) {
        apiRequest({ endpoint: `users/${id}`, method: 'DELETE', id }, cb);
    }

    getUserStores(id, cb) {
        apiRequest({ endpoint: `users/stores/${id}` }, cb);
    }

    addUserStore(payload, cb) {
        apiRequest({ endpoint: `users/stores`, method: 'POST', payload }, cb);
    }

    delUserStore(user, store, cb) {
        apiRequest({ endpoint: `users/stores/${user}/${store}`, method: 'DELETE', user }, cb);
    }

    sendWelcome(id, cb) {
        apiRequest({ endpoint: `users/welcome/${id}`, method: 'POST', id }, cb);
    }

    resetPassword(payload, cb) {
        apiRequest({ endpoint: `users/reset`, method: 'POST', payload }, cb);
    }

    setPassword(payload, cb) {
        apiRequest({ endpoint: `users/password`, method: 'POST', payload }, cb);
    }

}

export default new UsersAPI();