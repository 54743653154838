import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Title } from '../../components/Title';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { TextInput, InputWithButton } from '../../components/TextInput';
import OrdersAPI from '../../api/OrdersAPI';
import { SelectBox } from '../../components/SelectBox';
import { Loader } from '../../components/Loader';
import UsersAPI from '../../api/UsersAPI';
import StoresAPI from '../../api/StoresAPI';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import ChatsAPI from '../../api/ChatsAPI';

class ChatsPage extends Component {

    intervalID;

    constructor() {
        super();
        this.state = {
            isLoading: true,
            isClosed: false,
            page: 1,
            size: 30,
            count: 0,
            stores: [],
            status:'False',
            statuses: [{
                value: 'False',
                label: 'Active'
            }, {
                value: 'True',
                label: 'Closed'
            }],
            storeId: '',
            keyword: '',
            chats: [],
            userId: '',
            userLevel: 'user',
            errStoreId: '',
            errStatus: '',
        }

        this.loadChats = this.loadChats.bind(this);

    }

    initChats() {
        this.loadChats();
        this.intervalID = setInterval(this.loadChats.bind(this), 60000); // 60 secs
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    loadAllStores() {
        StoresAPI.getStores((data) => {
            var _arr = [];
            _arr.push({
                value: 'all',
                label: 'All Stores',
            });
            for (var i = 0; i < data.length; i++) {
                if (i === 0) {
                    this.setState({
                        storeId: 'all'
                    }, () => {
                        this.initChats();
                    })
                }
                _arr.push({
                    value: data[i].id,
                    label: data[i].title,
                });
            }
            this.setState({
                stores: _arr
            });
        });
    }

    loadUserStores() {
        UsersAPI.getUserStores(this.state.userId, (data) => {
            var _arr = [];
            for (var i = 0; i < data.length; i++) {
                if (i === 0) {
                    this.setState({
                        storeId: data[i].id
                    }, () => {
                        this.initChats();
                    })
                }
                _arr.push({
                    value: data[i].id,
                    label: data[i].title,
                });
            }
            this.setState({
                stores: _arr
            });
        });
    }

    componentDidMount() {
        this.checkUserIsPermitted();
        UsersAPI.getUserData((data) => {
            console.log(data);
            this.setState({
                userLevel: data.userLevel,
                userId: data.id,
            }, () => {
                if (this.state.userLevel === 'admin') {
                    this.loadAllStores();
                } else {
                    this.loadUserStores();
                }
            });
        })
    }

    checkUserIsPermitted() {
        UsersAPI.getUserData((data) => {
            UsersAPI.getUser(data.id, (_usr) => {
                if (!_usr.liveChat) {
                    this.props.history.push(`/`);
                }
            });
        });
    }

    loadChats() {

        this.setState({
            isLoading: true
        });

        window.scrollTo(0, 0);

        let _options = {
            store: this.state.storeId,
            status: this.state.status
        };

        ChatsAPI.getChats(_options, (data) => {
            this.setState({
                chats: data,
                isLoading: false,
            });
        });
    }

    onChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    render() {

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <Title title="Chat/Enquiries" />

                        <Container>

                            <div className="row">
                                <div className="col-sm-3">
                                    <SelectBox
                                        name="storeId"
                                        label="Store"
                                        value={this.state.storeId}
                                        options={this.state.stores}
                                        onChange={this.onChange.bind(this)}
                                        error={this.state.errStoreId}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <SelectBox
                                        name="status"
                                        label="Status"
                                        value={this.state.status}
                                        options={this.state.statuses}
                                        onChange={this.onChange.bind(this)}
                                        
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <label htmlFor={this.props.name}>&nbsp;</label>
                                        <div className="input-group">
                                            <SubmitButton
                                                text="Filter"
                                                className="btn"
                                                onClick={this.loadChats}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <div className="row">
                                <div className="col-sm-12">

                                    {this.state.isLoading ?
                                        <Loader />
                                        :
                                        <div>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th width="10%">Date/Time</th>
                                                        <th width="15%">Customer Name</th>
                                                        <th width="15%">Store</th>
                                                        <th width="40%" className="text-cell">Message</th>
                                                        <th width="10%">Type</th>
                                                        <th width="10%">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.chats.map((item, index) =>
                                                        <tr key={index}>
                                                            <td><Link to={`/chats/${item.id}`}>{moment(item.messageDate).format("ddd DD MMM YY @ HH:mm")}</Link></td>
                                                            <td>{item.fromName}</td>
                                                            <td>{item.storeName}</td>
                                                            <td className="text-cell">{item.message.substring(0, 150)}...</td>
                                                            <td>{item.chatType === 'chat' ? 'Live Chat' : 'Enquiry'}</td>
                                                            <td>{item.isClosed ? <span className="grey-warning">Closed</span> : <span className="yellow-warning">Active</span>}</td>
                                                        </tr>
                                                    )}
                                                    {this.state.chats.length === 0 &&
                                                        <tr>
                                                            <td colSpan="6" className="text-center">There are no chats/enquiries.</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    }

                                </div>
                            </div>

                        </Container>

                    </div>
                </div>
            </div>
        )

    }

}

ChatsPage.propTypes = {
    firstName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        firstName: state.account.firstName,
    }
};

export default connect(mapStateToProps)(ChatsPage);