import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MainLink } from './';
import { withRouter, Link } from 'react-router-dom';
import UsersAPI from '../../api/UsersAPI';

class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            version: null,
            pendingApproval: 0,
            support: 0,
            refunds: 0,
            user: {},
            userLevel: 'user',
            liveChat: false,
            accounting: false,
        }
    }

    componentDidMount() {
        /*import('../../meta.json').then(({ version }) => { 
            this.setState({ version });
        });*/
        import('../../../package.json').then(({ version }) => { 
            this.setState({ version });
        });
        UsersAPI.getUserData((data) => {
            console.log(data);
            this.setState({
                userLevel: data.userLevel,
                liveChat: data.liveChat,
                accounting: data.accounting,
            });
        })
    }

    render() {

        const path = this.props.location.pathname;

        return (
            <div>
            <div className="menu">
                <nav>
                    <ul>
                        <li className={path === '/' ? 'active' : ''}><MainLink title="Dashboard" icon="fa-home" url="/" {...this.props} /></li>
                        <li className={(path === '/products' || path.indexOf("products/") > -1) ? 'active' : ''}><MainLink title="Products" icon="fa-tags" url="/products" {...this.props} /></li>
                        <li className={(path === '/orders' || path.indexOf("orders/") > -1) ? 'active' : ''}><MainLink title="Orders" icon="fa-pallet" count={0} url="/orders" {...this.props} /></li>
                        {this.state.liveChat && <li className={(path === '/chats' || path.indexOf("chats/") > -1) ? 'active' : ''}><MainLink title="Chat/Enquiries" icon="fa-comments" url="/chats" {...this.props} /></li> }
                        {this.state.userLevel === 'admin' && <li className={(path === '/fraud' || path.indexOf("fraud/") > -1) ? 'active' : ''}><MainLink title="Fraud Risk" icon="fa-exclamation-triangle" url="/fraud" {...this.props} /></li> }
                        {(this.state.userLevel === 'admin' && this.state.accounting) && <li className={(path === '/accounts' || path.indexOf("accounts/") > -1) ? 'active' : ''}><MainLink title="Accounting" icon="fa-coins" url="/accounts" {...this.props} /></li> }
                    </ul>
                    <hr />
                        <ul>
                            {this.state.userLevel === 'admin' && <li className={(path === '/users' || path.indexOf("users/") > -1) ? 'active' : ''}><MainLink title="Users" icon="fa-users" url="/users" {...this.props} /></li> }
                            <li className={(path === '/stores' || path.indexOf("stores/") > -1) ? 'active' : ''}><MainLink title="Stores" icon="fa-building" url="/stores" {...this.props} /></li>
                            {this.state.userLevel === 'admin' && <li className={(path === '/filters' || path.indexOf("filters/") > -1) ? 'active' : ''}><MainLink title="Filters" icon="fa-cog" url="/filters" {...this.props} /></li> }
                        </ul>
                </nav>

                <nav className="base">
                    <ul>
                        {/*<li><MainLink title="Settings" icon="fa-cogs" url="/settings" {...this.props} /></li>*/}
                        <li className="meta">
                            <p><em>Version: {this.state.version}</em></p>
                        </li>
                    </ul>
                </nav>
            </div>
            </div>
        )
    }
};

Menu.propTypes = {
    isLoggedIn: PropTypes.bool,
    userName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.account.isLoggedIn,
        userName: state.account.userName,
    }
};

export default connect(mapStateToProps)(Menu);