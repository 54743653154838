import React from 'react';

const SubmitButton = (props) => {
    const { label, text, className, disabled = false, onClick } = props;
    return (

        <button 
        className={disabled ? "btn btn-disabled" : className ? className : "btn"} 
        disabled={disabled ? disabled : false}
        onClick={onClick}>{text}</button>


        
    )
};

export default SubmitButton;