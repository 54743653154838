import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { signOut } from '../../actions/account';
import UsersAPI from '../../api/UsersAPI';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { TextInput, TextArea, CurrencyInput, InputWithButton } from '../../components/TextInput';
//import ConsoleAPI from '../../api/ConsoleAPI';
import { runInThisContext } from 'vm';
import moment from 'moment';
import NotesAPI from '../../api/NotesAPI';

class Notes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
            userId: '',
            note: '',
            notes: [],
        }

        this.addNote = this.addNote.bind(this);

    }

    componentDidMount() {
        UsersAPI.getUserData((data) => {
            console.log(data);
            this.setState({
                userId: data.id,
            });
        });
        this.loadNotes();
    }

    onChange(name, value) {
        this.setState({
            [name]: value
        });
    }

    loadNotes() {
        NotesAPI.getNotes(this.state.id, (data) => {
            this.setState({
                notes: data,
                note: '',
            });
        });
    }

    addNote() {

        let payload = {
            linkedId: this.state.id,
            userId: this.state.userId,
            noteTime: new Date(),
            noteText: this.state.note,
        };

        NotesAPI.addNote(payload, (data) => {
            this.loadNotes();
        });
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-12">
                        <table>
                            <thead>
                                <tr>
                                    <th width="30%">Author/Date</th>
                                    <th width="70%" className="text-cell">Note</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.notes.length === 0 &&
                                <tr>
                                    <td colSpan="2" className="text-center">No notes added.</td>
                                </tr>
                                }
                                {this.state.notes.map((item, index) =>
                                <tr key={index}>
                                    <td valign="top" className="notes-table">{`${item.firstName} ${item.lastName}`}<div style={{fontSize: '11px'}}>{moment(item.noteTime).format("ddd DD MMM YY @ HH:mm")}</div></td>
                                    <td className="text-cell notes-table" valign="top">{item.noteText}</td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <TextArea 
                            name="note"
                            type="text"
                            value={this.state.note}
                            onChange={this.onChange.bind(this)}
                            error={this.state.errNote}
                            rows={3}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <SubmitButton
                            text="Add Note"
                            className="btn"
                            onClick={this.addNote}
                        />
                    </div>
                </div>
            </div>
        )
    };

}

Notes.propTypes = {
    isLoggedIn: PropTypes.bool,
    userName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.account.isLoggedIn,
        userName: state.account.userName,
    }
};

export default connect(mapStateToProps)(Notes);