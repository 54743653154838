import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { Provider } from 'react-redux';
import store from './config/store';
import { PersistGate } from 'redux-persist/integration/react';
import HttpsRedirect from 'react-https-redirect';
import "./scss/epos.scss";
import 'react-app-polyfill/ie9';

ReactDOM.render(
  <Provider store={store.store}>
    <PersistGate loading={null} persistor={store.persistor}>
      <Router onUpate={() => window.scrollTo(0, 0)}>
        <HttpsRedirect>
          <App />
        </HttpsRedirect>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();