class APIUtils {

    getBaseUrl() {
        return 'https://cgwebeposprod.azurewebsites.net/api/';
        
    }

    getHeaders(cb) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (localStorage.hasOwnProperty('userData')) {
            let user = JSON.parse(localStorage.getItem('userData'));
            headers.append('auth_token', user.id);
        }
        cb(headers);
    }

    postOptions(payload) {

        const options = {};
        options.method = "POST";
        options.payload = payload;
        options.headers = this.getHeadersPost();
        return options;
    }

    getHeadersPost() {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        if (localStorage.hasOwnProperty('userData')) {
            let user = JSON.parse(localStorage.getItem('userData'));
            headers.append('auth_token', user.id);
        }
        return headers;
    }


}

export default new APIUtils();